import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";

import UserCreatives_ST from "./creativesST";
import UserCreatives_FB from "./creativesFB";
import UserCreatives_AS from "./creativesAS";

const UserCreatives = () => {
    const [activeTab, setActiveTab] = React.useState(1);

    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const selectedTrackingProfile = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");

    React.useEffect(() => {
        if (activeTab === 2) setActiveTab(1);
    }, [selectedTrackingProfile]);
 
    return <div className="route__user__creatives">
        <div className="genericTabs">
            <div className={`genericTabs__tab ${activeTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(1)}>UX / UI</div>
            {/* {selectedTrackingProfile.startsWith("fb-") && <div className={`genericTabs__tab ${activeTab === 2 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(2)}>Facebook</div>} */}
            {(userDataSelector?.UserInfo?.Flags?.isAdmin || userDataSelector?.UserInfo?.Flags?.canSearchExternalAds) && <div className={`genericTabs__tab ${activeTab === 3 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(3)}>Search ads</div>}
        </div>

        {activeTab === 1 && <UserCreatives_ST />}
        {activeTab === 2 && <UserCreatives_FB />}
        {activeTab === 3 && <UserCreatives_AS />}
    </div>
};

export default UserCreatives;