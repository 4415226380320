import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";

const CampaignTrafficSourcesModal = props => {
    const [data, setData] = React.useState();
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [orders, setOrders] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const timestampRef = React.useRef();
    const curDefer = useDefer();
    const orderDefer = useDefer();

    const wrapTableItem = item => <span style={{padding: "0 0px 0 0px"}}>{item}</span>;

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = async (ts) => {
        if (timestampRef.current !== ts) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getCampaignTrafficSources`,
            data: {
                CampaignID: props?.item?.ID,
                filters: [
                    ...(Array.isArray(props.filters) ? props.filters : []),
                    ...(props.search ? [{or: [
                        { name: "IntegrationParams.st_source", op: "like", value: props.search }
                    ]}] : [])
                ],
                orders: orders ? [orders] : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            if (timestampRef.current !== ts) return;
            setGlobalSpinner(false);
        });
    };

    const prepareTableData = (data, column) => {
        let tmpCol = column.replace(/ /g, "_");
        if (tmpCol.endsWith("_IN")) tmpCol = tmpCol.substring(0, tmpCol.length - 3);

        if (tmpCol?.endsWith?.(".lastDate") && tmpCol?.startsWith?.("@")) {
            if (data === 0) return "Never";
            if (moment(data).isValid()) return moment(data).toDate().toLocaleString();
        };
        switch (tmpCol) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPR":
            case "CPR1000":
            case "CPV":
            case "ADP":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "DR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            case "CR_1":
                let tmpCR1 = Number(data);
                if (isNaN(tmpCR1)) return "-";
                return `${tmpCR1.toFixed(0)}%`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(2)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [props.search, props.filters, orders]);

    return <div className="modals__campaignTrackEventsModal__wrap__content" style={{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__campaignTrackEventsModal__wrap__content__left">
            <div className="modals__campaignTrackEventsModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    orderCB={e => orderDefer(() => setOrders(e), 500)}
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Traffic source", "Visits", "Conversions", "Gender", "CR", "Approved", "AR", "Revenue", "Spent", "Profit", "CPA", "CPAO", "ROI"]}
                    customColumns={["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"]}
                    showSpinner={globalSpinner}
                    spinnerColor={"#fff"}
                    style={{columnGap: "40px"}}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];

                        let out = [];

                        if (data.status === "ok") {
                            for (let item of data.data) {
                                out.push([
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(item.SourceName) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.Visits, "Visits")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.Conversions, "Conversions")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(`M ${prepareTableData(item.Gender_male, "CR_1")} / F ${prepareTableData(item.Gender_female, "CR_1")}`) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.CR, "CR")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.Approved, "Approved")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.AR, "AR")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.Revenue, "Revenue")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.Spent, "Spent")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.Profit, "Profit")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.CPA, "CPA")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.CPAO, "CPAO")) },
                                    { keyID: item.SourceName, type: "text", text: wrapTableItem(prepareTableData(item.ROI, "ROI")) },
                                ]);
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching sources!", color: "#f96666" }]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                        return out;
                    })()}
                    stickyHeaderClass="modals__campaignTrackEventsModal__wrap__content__left"
                    stickyHeader={60}
                />
            </div>
        </div>
    </div>;
};

export default CampaignTrafficSourcesModal;