import React from "react";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import { countries } from "../../../modules/countryModule";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";
import StyledButton from "../../../components/styledComponents/Button";
import StyledInput from "../../../components/styledComponents/Input";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

import YesNoModal from "../../../components/modals/YesNoModal";

const UserCreatives_ST = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [data, setData] = React.useState();
    const [allCreativeNames, setAllCreativeNames] = React.useState();
    const [search, setSearch] = React.useState("");
    const [dateFilter, setDateFilter] = React.useState();
    const [creativeForFilter, setCreativeForFilter] = React.useState();
    const [creativeTypeFilter, setCreativeTypeFilter] = React.useState();
    const [creativeCountryFilter, setCreativeCountryFilter] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [selectedCreative, setSelectedCreative] = React.useState(null);
    const [canApplyFilters, setCanApplyFilters] = React.useState(false);

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const creativeForTypesSelector = useSelector(state => state?.types?.creativeForTypes ?? {});
    const timestampRef = React.useRef(Date.now());
    const fileWidth_startRef = React.useRef();
    const fileWidth_endRef = React.useRef();
    const fileHeight_startRef = React.useRef();
    const fileHeight_endRef = React.useRef();

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();
    const curDispatch = useDispatch();

    const prepareFilters = () => {
        let out = [];
        if (!search) return out;

        for (let item of search.split(" ")) {
            out.push({or: [
                {name: "CreativeName", op: "like", value: item},
                {name: "FileType", op: "like", value: item},
                {name: "CustomID", op: "like", value: item}
            ]})
        };

        return out.length === 0 ? null : {and: out};
    };

    const getAllCreativeNames = ts => {
        if (timestampRef.current !== ts) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreativeNames`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setAllCreativeNames(res.data.status === "ok" ? res.data.data : []);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setAllCreativeNames([]);
        });
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);
        getAllCreativeNames(ts);

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()}
            ]});
        };

        let additionalFilters = [];
        if (creativeCountryFilter) {
            additionalFilters.push({name: "Country", op: "eq", value: creativeCountryFilter});
        };
        if (creativeTypeFilter) {
            additionalFilters.push({name: "CreativeType", op: "eq", value: creativeTypeFilter});
        };
        if (fileWidth_startRef.current.value || fileWidth_endRef.current.value) {
            let start = null;
            let end = null;

            if (fileWidth_startRef.current.value) {
                start = Number(fileWidth_startRef.current.value);
                if (isNaN(start)) start = null;
            };
            if (fileWidth_endRef.current.value) {
                end = Number(fileWidth_endRef.current.value);
                if (isNaN(end)) end = null;
            };

            if (start && end) {
                if (start > end) [start, end] = [end, start];
            };
            additionalFilters.push({and: [
                (start ? {name: "CreativeWidth", op: "geq", value: start} : null),
                (end ? {name: "CreativeWidth", op: "leq", value: end} : null)
            ].filter(f => f)});
        };
        if (fileHeight_startRef.current.value || fileHeight_endRef.current.value) {
            let start = null;
            let end = null;

            if (fileHeight_startRef.current.value) {
                start = Number(fileHeight_startRef.current.value);
                if (isNaN(start)) start = null;
            };
            if (fileHeight_endRef.current.value) {
                end = Number(fileHeight_endRef.current.value);
                if (isNaN(end)) end = null;
            };

            if (start && end) {
                if (start > end) [start, end] = [end, start];
            };
            additionalFilters.push({and: [
                (start ? {name: "CreativeHeight", op: "geq", value: start} : null),
                (end ? {name: "CreativeHeight", op: "leq", value: end} : null)
            ].filter(f => f)});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    prepareFilters(),
                    ...finalDateFilters,
                    ...additionalFilters,
                    (selectedCreative ? {name: "CreativeName", op: "eq", value: selectedCreative} : null),
                    (creativeForFilter ? {name: "CreativeFor", op: "eq", value: creativeForFilter} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setCanApplyFilters(false);
            setSpinner(false);
        });
    };

    const continueData = (ts) => {
        if (!canPaginate) return;
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()},
                (creativeForFilter ? {name: "CreativeFor", op: "eq", value: creativeForFilter} : null)
            ]});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    prepareFilters(),
                    ...finalDateFilters,
                    {not: [
                        {name: "ID", op: "in", value: data.data.map(d => d.ID)},
                    ]},
                    (selectedCreative ? {name: "CreativeName", op: "eq", value: selectedCreative} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const removeCreative = (e, item) => {
        let target = {currentTarget: e?.target};

        animateBox(target, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove a creative, are You sure?", <br/>, "This action is irreversible"]}
            buttonLeftText={"No"}
            buttonRightText={"Yes"}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);

                let hadError = false;
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/creatives/removeCreative`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "error") hadError = true;
                }).catch(() => {
                    hadError = true;
                });

                args.spinner(false);
                args.disabledAll(false);
                if (hadError) {
                    args.errorMessage("There was an error while removing the creative!");
                } else {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    curDefer(() => getData(ts), 0);

                    args.close();
                };
            }}
        />);
    };

    const checkAdditionalData = (ad, creative, noWrap = false) => {
        if (!ad) return null;
        if (!ad?.["integration"]) {
            if (noWrap) return creative?.CustomID;
            return <span style={{
                color: "gray",
                marginRight: "auto",
                fontSize: "14px"
            }}>{creative?.CustomID}</span>;
        };
        let nameSplit = String(ad["integration"]).split(":");

        let intName = nameSplit.shift();
        let intValue = nameSplit.join(":");

        if (noWrap) return `${intName.charAt(0)}-${intValue}`;
        return <span style={{
            color: "gray",
            marginRight: "auto",
            fontSize: "14px"
        }}>{intName.charAt(0)}{`-${intValue}`}</span>;
    };

    const getFirstCTA = (ctaItems) => {
        if (!Array.isArray(ctaItems)) return "";
        for (let ctaItem of ctaItems) {
            if (ctaItem.cta) return ctaItem.cta;
        };
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, []);

    React.useEffect(() => {
        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts), 500);
        };
        setCanApplyFilters(true);
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [search, selectedCreative, dateFilter, creativeForFilter, creativeTypeFilter, creativeCountryFilter]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__user__creatives__st">
        {/* <div className="route__user__creatives__st__filters" style={{
            gridTemplateColumns: (userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddCreatives) ? null : "1fr 350px"
        }}>
            <FilterBySearch onChange={e => setSearch(e)} />
            <AdvancedDropdown
                headline="Creative"
                showSearch={true}
                data={Array.isArray(allCreativeNames) ? [{key: "all", name: "All creatives", value: null},...allCreativeNames.map(c => {
                    return {
                        key: c.CreativeName,
                        name: `${c.CreativeName} (${c.Count})`,
                        value: c.CreativeName
                    };
                })] : null}
                onChange={e => {
                    if (selectedCreative !== e?.value) setSelectedCreative(e?.value);
                }}
                selected={(()=>{
                    if (selectedCreative === undefined) return null;
                    if (!Array.isArray(allCreativeNames)) return null;
                    if (selectedCreative === null) return 0;

                    return allCreativeNames.indexOf(allCreativeNames.find(ac => ac.CreativeName === selectedCreative)) + 1;
                })()}
            />
            <AdvancedDropdown
                headline="For"
                data={[{key: "all", name: "All", value: null},...Object.keys(creativeForTypesSelector).map(key => {
                    return {key: key, name: creativeForTypesSelector[key], value: key}
                })]}
                onChange={e => creativeForFilter !== e?.value && setCreativeForFilter(e?.value)}
                selected={(()=>{
                    if (!creativeForFilter) return 0;
                    return Object.keys(creativeForTypesSelector).indexOf(creativeForFilter) + 1;
                })()}
            />
            <FilterByDate defaultValue="all" onChange={e => setDateFilter(e)} />

            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddCreatives) && <StyledButton onClick={e => animateBox(e, <AddCreative onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                curDefer(() => getData(ts), 0);
            }} />)}>
                <img src="/images/icon_close.svg" />
                <span>Add</span>
            </StyledButton>}
        </div> */}

        <div className="route__user__creatives__st__content">
            <div className="route__user__creatives__st__content__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" align="center" />
            </div>
            {data ? <>
                {data.status === "ok" ? <>
                    {data.data.map(item => {
                        return <div className="route__user__creatives__st__content__item" key={`creative-${item.ID}`} onClick={e => {
                            animateBox(<CreativeModal ID={checkAdditionalData(item?.AdditionalData ?? {}, item, true)} item={item} />)
                        }}>      
                            <div className="route__user__creatives__st__content__item__header">
                                {checkAdditionalData(item?.AdditionalData ?? {}, item)}  
                                <span>{creativeForTypesSelector[item.CreativeFor]}</span>
                            </div>

                            <img src={(()=>{
                                if (item.FileType === "image") return item.File;
                                if (item.FileType === "video") return "/images/fileTypes/video.svg";
                                return "/images/fileTypes/file.svg";
                            })()} />

                            {getFirstCTA(item.CTACopies) && <div className="route__user__creatives__st__content__item__ctaCopies">
                                <div className="route__user__creatives__st__content__item__ctaCopies__copy">
                                    <span>{getFirstCTA(item.CTACopies)}</span>
                                </div>
                            </div>}

                            <div className="route__user__creatives__st__content__item__buttons">
                                <img src={`/images/countryFlags/${item.Country?.toLowerCase?.() ?? "rs"}.png`} style={{
                                    marginRight: (item.CreativeWidth || item.CreativeHeight) ? "0px" : null,
                                    height: "14px"
                                }} />
                                {(item.CreativeWidth || item.CreativeHeight) && <p style={{color: "gray", marginRight: "auto", fontSize: "14px"}}>{item.CreativeWidth ?? "0"}x{item.CreativeHeight ?? "0"}</p>}

                                {item.FileType === "file" && <div className="route__user__creatives__st__content__item__buttons__btn" onClick={e => {
                                    e.stopPropagation();
                                    window.open(item.File, "_blank");
                                    navigator.clipboard.writeText(checkAdditionalData(item?.AdditionalData ?? {}, item, true));
                                }} style={{backgroundImage: `url("images/icon_export.svg")`}} />}
                                {(item.FileType === "video" || item.FileType === "image") && <div className="route__user__creatives__st__content__item__buttons__btn" onClick={e => window.open((()=>{
                                    navigator.clipboard.writeText(checkAdditionalData(item?.AdditionalData ?? {}, item, true));
                                    e.stopPropagation();
                                    let tmpURL = item.File;
                                    tmpURL += tmpURL.includes("?") ? "&" : "?";
                                    tmpURL += "download=1";
                                    return tmpURL;
                                })(), "_blank", "download")} style={{backgroundImage: `url("images/icon_export.svg")`}} />}
                                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.ID === item.CreatedBy) && <div className="route__user__creatives__st__content__item__buttons__btn" style={{backgroundImage: `url("/images/integrations/integration_remove.svg")`}} onClick={(e) => {
                                    e.stopPropagation();
                                    removeCreative(e, item);
                                }} />}
                            </div>
                        </div>
                    })}

                    {canPaginate && <div style={{opacity: 0, paddingBottom: "10px", marginBottom: "10px"}} ref={curOnScreen.measureRef}></div>}
                    {data.data.length === 0 && <p>Nothing to show...</p>}
                </> : <p className="route__user__creatives__st__content__infoP">Error while fetching data!</p>}
            </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
        </div>
        <div className="route__user__creatives__st__sideFilters">
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddCreatives) && <StyledButton onClick={e => animateBox(e, <AddCreative onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                curDefer(() => getData(ts), 0);
            }} />)}>
                <img src="/images/icon_close.svg" />
                <span>Add creative</span>
            </StyledButton>}

            {canApplyFilters && <StyledButton onClick={() => {
                setCanApplyFilters(false);
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
            }}>Apply filters</StyledButton>}

            <FilterBySearch onChange={e => setSearch(e)} />
            <FilterByDate defaultValue="all" onChange={e => setDateFilter(e)} />

            <AdvancedDropdown
                headline="Creative"
                showSearch={true}
                data={Array.isArray(allCreativeNames) ? [{key: "all", name: "All", value: null},...allCreativeNames.map(c => {
                    return {
                        key: c.CreativeName,
                        name: `${c.CreativeName} (${c.Count})`,
                        value: c.CreativeName
                    };
                })] : null}
                onChange={e => {
                    if (selectedCreative !== e?.value) setSelectedCreative(e?.value);
                }}
                selected={(()=>{
                    if (selectedCreative === undefined) return null;
                    if (!Array.isArray(allCreativeNames)) return null;
                    if (selectedCreative === null) return 0;

                    return allCreativeNames.indexOf(allCreativeNames.find(ac => ac.CreativeName === selectedCreative)) + 1;
                })()}
            />

            <AdvancedDropdown
                headline="For"
                data={[{key: "all", name: "All", value: null},...Object.keys(creativeForTypesSelector).map(key => {
                    return {key: key, name: creativeForTypesSelector[key], value: key}
                })]}
                onChange={e => creativeForFilter !== e?.value && setCreativeForFilter(e?.value)}
                selected={(()=>{
                    if (!creativeForFilter) return 0;
                    return Object.keys(creativeForTypesSelector).indexOf(creativeForFilter) + 1;
                })()}
            />

            <AdvancedDropdown
                headline="Type"
                data={[
                    {key: "any", name: "All", value: null},
                    {key: "image", name: "Image", value: "image"},
                    {key: "video", name: "Video", value: "video"}
                ]}
                onChange={e => creativeTypeFilter !== e?.value && setCreativeTypeFilter(e?.value)}
                selected={(()=>{
                    if (!creativeTypeFilter) return 0;
                    switch (creativeTypeFilter) {
                        case "image": return 1;
                        case "video": return 2;
                        default: return null;
                    };
                })()}
            />

            <AdvancedDropdown
                headline="Country"
                data={[
                    {key: "any", name: "All", value: null},
                    ...countries.map(c => {
                        return {key: c.code, name: c.name, value: c.code, image: `/images/countryFlags/${c.code.toLowerCase()}.png`};
                    })
                ]}
                showSearch={true}
                onChange={e => creativeCountryFilter !== e?.value && setCreativeCountryFilter(e?.value)}
                selected={(()=>{
                    if (!creativeCountryFilter) return 0;
                    return countries.indexOf(countries.find(c => c.code === creativeCountryFilter)) + 1;
                })()}
            />

            <div className="route__user__creatives__st__sideFilters__split">
                <p>File width</p>
                <StyledInput ref={fileWidth_startRef} onChange={() => setCanApplyFilters(true)} placeholder="From" alternateStyle={true} />
                <StyledInput ref={fileWidth_endRef} onChange={() => setCanApplyFilters(true)} placeholder="To" alternateStyle={true} />
            </div>
            <div className="route__user__creatives__st__sideFilters__split">
                <p>File height</p>
                <StyledInput ref={fileHeight_startRef} onChange={() => setCanApplyFilters(true)} placeholder="From" alternateStyle={true} />
                <StyledInput ref={fileHeight_endRef} onChange={() => setCanApplyFilters(true)} placeholder="To" alternateStyle={true} />
            </div>
        </div>
    </div>
};

const AddCreative = (props) => {
    const [files, setFiles] = React.useState([]);
    const [allCreativeNames, setAllCreativeNames] = React.useState();
    const [creativeFor, setCreativeFor] = React.useState();
    const [selectedCountries, setSelectedCountries] = React.useState([]);
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadErrors: false,
        inputs: []
    });
    const [spinner, setSpinner] = React.useState(false);
    const [creativeName, setCreativeName] = React.useState();
    const [ctaCopies, setCTACopies] = React.useState([{cta: "", primary: ""}]);

    const countryResetRef = React.useRef();

    const creativeForTypesSelector = useSelector(state => state?.types?.creativeForTypes ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const selectFiles = f => {
        if (!(f instanceof FileList)) return;
        if (f.length === 0) return;

        let toAdd = [];
        for (let item of f) {
            if (!files.find(t => t.name === item.name && t.size === item.size)) toAdd.push(item);
        };
        setFiles(f => [...f, ...toAdd]);
    };

    const addCreative = async (e) => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadErrors: false, inputs: []}});

        let initialData = {
            CreativeName: creativeName,
            CreativeFor: creativeFor,
            CTACopies: ctaCopies.filter(f => f?.cta || f?.primary)
        };

        if (!initialData.CreativeName) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Creative name can't be empty!", inputs: ["name"]}});
        if (!initialData.CreativeFor) return setInfoP(ip => {return {...ip, hadErrors: true, error: "For can't be empty!", inputs: ["for"]}});
        if (selectedCountries.length === 0) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Country can't be empty!", inputs: ["country"]}});
        if (files.length === 0) return setInfoP(ip => {return {...ip, hadErrors: true, error: "At least 1 file has to be selected!", inputs: []}});
        for (let f of files) {
            if (!f.type.startsWith("image/") && !f.type.startsWith("video/")) return setInfoP(ip => {return {...ip, hadErrors: true, error: `File ${f.name} is invalid!`, inputs: []}});
        };

        let i = 0;
        const failedFiles = [];
        setSpinner(true);
        for (let file of files) {
            let fileType = null;
            if (file.type.startsWith("image/")) fileType = "image";
            if (file.type.startsWith("video/")) fileType = "video";

            let fd = new FormData();
            if (fileType === "image") {
                fd.append("ImageName", `creative-${initialData.CreativeName}-${i++}`);
                fd.append("tag", "creative");
                fd.append("image", file);
            } else {
                fd.append("FileName", `creative-${initialData.CreativeName}-${i++}`);
                fd.append("tag", "creative");
                fd.append("file", file);
                fd.append("FileType", fileType);
            };

            let upload = await axios({
                method: "POST",
                url: `${backendModule.backendURL}${fileType === "image" ? "/images/uploadImage" : "/files/uploadFile"}`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => res.data.status === "ok"? res.data.data?.url : null).catch(() => null);
            if (!upload) {
                failedFiles.push(file);
                continue;
            };

            for (let c of selectedCountries) {
                let final = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/creatives/addCreative`,
                    data: {
                        ...initialData,
                        Country: c,
                        File: upload,
                        FileType: fileType,
                        FileName: file.name
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);
    
                if (final.status !== "ok") {
                    failedFiles.push(file);
                    let imgName = upload.split("/").pop();
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}${fileType === "image" ? "/images/deleteImageByFilename" : "/files/deleteFileByFilename"}`,
                        data: {
                            FileName: imgName
                        },
                        ...backendModule.axiosConfig
                    }).catch(() => null);
                    break;
                };
            };
        };

        if (failedFiles.length > 0) {
            let errs = [`There was an error while uploading ${failedFiles.length} files!`, <br/>, "The following files could not be uploaded:",<br/>];
            for (let file of failedFiles) {
                errs.push(<span style={{color: "rgb(201, 194, 255)"}}>{file?.name ?? "unknown"}</span>, <br/>);
            };

            animateBox({currentTarget: e?.target}, <YesNoModal
                heading="Error while uploading"
                text={errs}
                buttonLeftHidden={true}
                isRightButtonNormal={true}
                buttonRightText="Ok"
            />);
        };
        setSpinner(false);
        props.onClose();
        props.onChange();
    };

    const processCopyText = (index, type, text) => {
        setCTACopies(ctaCopies => {
            let tmp = [...ctaCopies].map(c => {return {...c}});
            tmp[index][type] = text;

            tmp = tmp.filter(tt => tt?.cta || tt?.primary);
            if (tmp.length === 0) {
                tmp.push({cta: "", primary: ""});
            } else {
                if (tmp[tmp.length-1].cta || tmp[tmp.length-1].primary) {
                    tmp.push({cta: "", primary: ""});
                };
            };

            return tmp;
        });
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreativeNames`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllCreativeNames(res.data);
        }).catch(() => {
            setAllCreativeNames(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__creatives__st__addCreative genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add creative</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("for") ? "genericModal__wrap__input--error" : ""}`}>
                <p>For</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="For"
                    data={Object.keys(creativeForTypesSelector).map(key => {
                        return {key, name: creativeForTypesSelector[key], value: key}
                    })}
                    onChange={e => {
                        setCreativeFor(e?.value);
                    }}
                    selected={(()=>{
                        if (!creativeFor) return null;
                        return Object.keys(creativeForTypesSelector).indexOf(creativeFor)
                    })()}
                />
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("name") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Illness</p>
                {allCreativeNames ? <>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Illness"
                        data={[
                            {value: null, name: "New illness", alwaysVisible: true, saveValue: true, showValue: true, style: {color: "rgb(146, 240, 157)"}},
                            ...(allCreativeNames?.status ===  "ok" ? allCreativeNames.data : []).map(name => {
                                return {name: name.CreativeName, value: name.CreativeName};
                            })
                        ]}
                        onChange={e => {
                            setCreativeName(e?.value);
                        }}
                        selected={(()=>{
                            if (!allCreativeNames) return null;
                            if (allCreativeNames.status !== "ok") return null;

                            let tmpIndex = allCreativeNames.data.indexOf(allCreativeNames.data.find(a => a.CreativeName === creativeName));
                            if (tmpIndex === -1) return null;
                            return tmpIndex + 1;
                        })()}
                    />
                </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
            </div>

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("country") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Country</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    inlinePlaceholder="Country"
                    data={countries.map(c => {
                        return {name: c.name, value: c.code}
                    })}
                    onChange={e => {
                        if (e?.value) {
                            if (!selectedCountries.includes(e?.value)) setSelectedCountries(sc => [...sc, e?.value]);
                            if (countryResetRef.current) countryResetRef.current();
                        };
                    }}
                    selected={(()=>null)()}
                    onReset={r => {
                        countryResetRef.current = r;
                    }}
                />
                <div className="route__user__creatives__st__addCreative__pills">
                    {selectedCountries.map(c => {
                        return <div className="route__user__creatives__st__addCreative__pills__pill" onClick={() => {
                            setSelectedCountries(sc => sc.filter(scf => scf !== c));
                        }}>
                            <img src={`/images/countryFlags/${c.toLowerCase()}.png`} />
                            <span>{c}</span>
                        </div>
                    })}
                </div>
            </div>

            <div className="genericModal__wrap__input genericModal__wrap__input--split">
                <p>Copy text</p>
                
                {ctaCopies.map((cta, ctaIdx) => {
                    return <>
                        <input type="text" placeholder="CTA Copy text" value={cta.cta} onChange={e => processCopyText(ctaIdx, "cta", e.target.value)} />
                        <input type="text" placeholder="Primary Copy text" value={cta.primary} onChange={e => processCopyText(ctaIdx, "primary", e.target.value)} />
                    </>;
                })}
            </div>

            <input type="file" accept="image/*, video/*" style={{display: "none"}} multiple={true} onChange={e => selectFiles(e?.target?.files)} />
            <StyledButton className="route__user__creatives__st__addCreative__uploadBtn" onClick={e => e?.currentTarget?.parentNode?.querySelector?.("input[type=file]")?.click?.()}>
                <img src="/images/fileTypes/file.svg" />
                <span>Click to upload a file</span>
            </StyledButton>

            <div className="route__user__creatives__st__addCreative__list">
                {files.map((f, fIdx) => <div className="route__user__creatives__st__addCreative__list__item">
                    <p>{f?.name}</p>
                    <StyledButton isSecondary={true} onClick={() => {
                        setFiles(f => f.filter((_, f2Idx) => f2Idx !== fIdx));
                    }}>Remove</StyledButton>
                </div>)}
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={(e) => !spinner && addCreative(e)}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Add"}
                </div>
            </div>

            <p className="genericModal__wrap__infoP" style={{
                opacity: infoP.hadErrors ? 1 : 0
            }}>{infoP.error}</p>

        </div>
    </div>
};

const CreativeModal = props => {
    const [activeTab, setActiveTab] = React.useState(1);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const creativeForTypesSelector = useSelector(state => state?.types?.creativeForTypes ?? {});

    const mainRef = React.useRef();

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    return <div className="route__user__creatives__st__modal" onClick={onClose}>
        <div className="route__user__creatives__st__modal__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="route__user__creatives__st__modal__wrap__top">
                <div className="route__user__creatives__st__modal__wrap__top__left">Creative{props.ID ? ` ${props.ID}` : ""}</div>

                <div className="route__user__creatives__st__modal__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__creatives__st__modal__wrap__content">
                <div className="genericTabs">
                    <div className={`genericTabs__tab ${activeTab === 1 ? "genericTabs__tab--active" : ""}`}>Creative</div>
                </div>

                <div className="route__user__creatives__st__modal__wrap__content__wrap">
                    <div className="route__user__creatives__st__modal__wrap__content__wrap__left">
                        {props.item.FileType === "image" && <img src={props.item.File} />}
                        {props.item.FileType === "video" && <video src={props.item.File} controls />}
                        <StyledButton style={{marginTop: "5px"}} onClick={() => {
                            switch (props.item.FileType) {
                                case "file":
                                    window.open(props.item.File, "_blank");
                                    navigator.clipboard.writeText(props.ID);
                                    break;
                                case "image":
                                case "video":
                                    navigator.clipboard.writeText(props.ID);
                                    window.open((()=>{
                                        let tmpURL = props.item.File;
                                        tmpURL += tmpURL.includes("?") ? "&" : "?";
                                        tmpURL += "download=1";
                                        return tmpURL;
                                    })(), "_blank", "download");
                                    break;
                            };
                        }}>Download</StyledButton>
                    </div>
                    <div className="route__user__creatives__st__modal__wrap__content__wrap__right">
                        <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi">
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>ID</p>
                                <p>{props.ID}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Creative</p>
                                <p>{props.item.CreativeName}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Type</p>
                                <p>{props.item.FileType.charAt(0).toUpperCase()}{props.item.FileType.substring(1, props.item.FileType.length)}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Country</p>
                                <p style={{display: "grid", gridTemplateRows: "auto", gridTemplateColumns: "max-content 1fr", alignItems: "center", gap: "5px"}}>
                                    <img src={`/images/countryFlags/${String(props.item.Country ?? "?").toLowerCase()}.png`} />
                                    <span>{countries.find(c => c.code === props.item.Country)?.name}</span>
                                </p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>For source</p>
                                <p>{creativeForTypesSelector[props.item.CreativeFor] ?? "?"}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Content dimensions</p>
                                <p>{props.item.CreativeWidth ?? "0"} x {props.item.CreativeHeight ?? "0"}</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Date added</p>
                                <p>{moment(props.item.createdAt).toDate().toLocaleString()}</p>
                            </div>
                            {/* <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Likes</p>
                                <p>0</p>
                            </div>
                            <div className="route__user__creatives__st__modal__wrap__content__wrap__right__kpi__item">
                                <p>Dislikes</p>
                                <p>0</p>
                            </div> */}
                        </div>

                        {props.item.CTACopies?.length > 0 && <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            style={{columnGap: "40px"}}
                            headers={["Generated by", "CTA text", "Primary text"]}
                            customColumns={["max-content", "max-content", "max-content"]}
                            data={props.item.CTACopies.map((copy, copyIdx) => {
                                return [
                                    {keyID: copyIdx, type: "text", text: copy.isAI ? "AI" : "User", style: {color: copy.isAI ? "aqua" : "orange"}},
                                    {keyID: copyIdx, type: "text", text: copy.cta, style: {maxWidth: "500px", wordBreak: "break-word"}},
                                    {keyID: copyIdx, type: "text", text: copy.primary, style: {maxWidth: "500px", wordBreak: "break-word"}}
                                ];
                            })}
                        />}
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default UserCreatives_ST;