import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";

import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";

import Spinner from "../../../components/customComponents/Spinner";
import FilterByDate from "../../../components/filters/FilterByDate";
import { FilteredCustomTable } from "../../../components/customComponents/Table";

const AccountManager_userActivity = () => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySelector = useSelector(state => state?.types?.currencySign ?? "?");

    const getData = () => {
        if (dateFilters === undefined) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/getUserActivity`,
            data: {
                dateStart: dateFilters?.start?.toDate?.()?.getTime?.(),
                dateEnd: dateFilters?.end?.toDate?.()?.getTime?.()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => setData(backendModule.genericError));
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            let a1Compare = a1?.text;
            let b1Compare = b1?.text;

            a1Compare = String(a1Compare);
            b1Compare = String(b1Compare);

            if (a1Compare.endsWith("%") || a1Compare.endsWith(currencySelector)) a1Compare = Number(a1Compare.split(" ")[0]);
            if (b1Compare.endsWith("%") || b1Compare.endsWith(currencySelector)) b1Compare = Number(b1Compare.split(" ")[0]);

            if (!isNaN(a1Compare) && !isNaN(b1Compare)) {
                a1Compare = Number(a1Compare);
                b1Compare = Number(b1Compare);
                return a1Compare > b1Compare ? asc : desc;
            } else {
                return a1Compare > b1Compare ? asc : desc;
            };
        });
    };

    React.useEffect(() => {
        getData();
    }, [dateFilters]);

    return <div className="route__user__accountManager">
        <FilterByDate onChange={e => setDateFilters(e)} />

        {data ? <>
            <FilteredCustomTable
                accent="#6C5DD3"
                theme={themeSelector}
                headers={["Account", "Campaigns created", "Adsets created", "Ads created", "Conversions", "Daily cap", ""].filter(f => f)}
                customColumns={["max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content"].filter(f => f)}
                style={{ columnGap: "40px", marginTop: "20px" }}
                orderCB={setOrders}
                data={(() => {
                    if (data.status !== "ok") return [[{ keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: { color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight } }]];

                    let out = [];
                    for (let usr of data.data) {
                        for (let profile of usr.Profiles) {
                            if (
                                !profile?.Totals?.Campaigns &&
                                !profile?.Totals?.Adsets &&
                                !profile?.Totals?.Ads
                            ) continue;
                            out.push([
                                {
                                    keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: <p style={{ display: "flex", flexDirection: "column" }}>
                                        <span>{profile.IntegrationID}</span>
                                        <span>{`${usr.Username} - ${profile.Name}`}</span>
                                    </p>
                                },
                                {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile?.Totals?.Campaigns ?? 0},
                                {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile?.Totals?.Adsets ?? 0},
                                {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile?.Totals?.Ads ?? 0},
                                {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile?.Totals?.Conversions ?? 0},
                                {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile?.Totals?.DailyCap ?? 0},
                                {keyID: `${usr.ID}-${profile.IntegrationID}`, type: "button", text: "View", onClick: () => animateBox(<AccountManager_userActivity_ads ads={profile.Totals.AdData} />)}
                            ].filter(f => f))
                        };
                    };

                    out = orderData(out, ["Account", "Campaigns created", "Adsets created", "Ads created", "Conversions", "Daily cap"]);
                    if (out.length === 0) out.push([{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }]);
                    return out;
                })()}
            />
        </> : <Spinner style={{ width: "32px", height: "32px" }} color={themeSelector === "dark" ? "white" : "black"} />}
    </div>
};

const AccountManager_userActivity_ads = props => {
    const wrapRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__user__accountManager__activityAds" onClick={() => onClose()}>
        <div className="route__user__accountManager__activityAds__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__accountManager__activityAds__wrap__top">
                <div className="route__user__accountManager__activityAds__wrap__top__left">Ads</div>
                <div className="route__user__accountManager__activityAds__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__user__accountManager__activityAds__wrap__content">
                <FilteredCustomTable
                    canAnimate={false}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["Campaign", "Adset", "Ad"]}
                    style={{columnGap: "20px"}}
                    customColumns={["max-content", "max-content", "max-content"]}
                    data={(()=>{
                        let out = [];

                        for (let item of props.ads) {
                            out.push([
                                {keyID: item.AdID, type: "text", text: <p className="route__user__accountManager__activityAds__wrap__content__ad">
                                    <span>{item.CampaignID}</span>
                                    <span>{item.CampaignName}</span>
                                </p>},
                                {keyID: item.AdID, type: "text", text: <p className="route__user__accountManager__activityAds__wrap__content__ad">
                                    <span>{item.AdsetID}</span>
                                    <span>{item.AdsetName}</span>
                                </p>},
                                {keyID: item.AdID, type: "text", text: <p className="route__user__accountManager__activityAds__wrap__content__ad">
                                    <span>{item.AdID}</span>
                                    <span>{item.AdName}</span>
                                </p>}
                            ]);
                        };

                        if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                        return out;
                    })()}
                />
            </div>
        </div>
    </div>
};

export default AccountManager_userActivity