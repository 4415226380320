import React from "react";
import "./index.scss";

import moment from "moment";

const FilterByDate = props => {
    const [dropdownVisible, setDropdownVisible] = React.useState();
    const [selectedDateType, setSelectedDateType] = React.useState((()=>{
        if (props.defaultValue) {
            if (typeof(props.defaultValue) === "string") return props.defaultValue;
            return props.defaultValue.type;
        };
        return "24h";
    })());
    const [customDates, setCustomDates] = React.useState((()=>{
        if (props.defaultValue) {
            if (typeof(props.defaultValue) === "object") {
                return {start: props.defaultValue.start, end: props.defaultValue.end};
            };
        };
        return {start: null, end: null};
    })());

    const curMaxHeightRef = React.useRef();
    const dropdownRef = React.useRef();
    const startDateRef = React.useRef();
    const endDateRef = React.useRef();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onApplyClick();
        }
    };

    const onApplyClick = () => {
        if (!startDateRef.current || !endDateRef.current) return;

        let start = moment(startDateRef.current.value);
        let end = moment(endDateRef.current.value);

        if (!start.isValid() || !end.isValid()) return;

        if (start.isAfter(end)) {
            [start, end] = [end, start];
        };

        setCustomDates({start: start.startOf("day"), end: end.endOf("day")});
        if (dropdownVisible) setDropdownVisible(false);
    }; 

    // {start: ..., end: ...} || null
    const prepareDate = () => {
        let startDate = moment().startOf("day");
        let endDate = moment().endOf("day");

        switch (selectedDateType) {
            case "all": return null;
            case "1h":
                endDate = moment();
                startDate = moment().add(-1, "hours");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "4h":
                endDate = moment();
                startDate = moment().add(-4, "hours");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "24h":
                endDate = moment();
                startDate = moment().add(-1, "days");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "today": return {start: startDate, end: endDate, type: selectedDateType};
            case "yesterday":
                startDate.add(-1, "days");
                endDate.add(-1, "days");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "7days":
                startDate.add(-7, "days");
                endDate.add(-1, "days");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "30days":
                startDate.add(-29, "days");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "thisMonth":
                startDate = startDate.startOf("month");
                endDate = endDate.endOf("month");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "lastMonth":
                startDate = startDate.add(-1, "month").startOf("month");
                endDate = endDate.add(-1, "month").endOf("month");
                return {start: startDate, end: endDate, type: selectedDateType};
            case "custom":
                return (customDates.start && customDates.end) ? {...customDates, type: selectedDateType} : undefined;
        };
    };

    React.useEffect(() => {
        if (!dropdownRef?.current) return;
        if (curMaxHeightRef.current) return;

        dropdownRef.current.maxHeight = null;
        let rect = dropdownRef.current.getBoundingClientRect();
        curMaxHeightRef.current = rect.height;
        dropdownRef.current.style.maxHeight = 0;
    }, [dropdownRef.current]);

    React.useEffect(() => {
        if (!dropdownRef?.current) return;
        if (!curMaxHeightRef?.current) return;

        if (dropdownVisible) {
            dropdownRef.current.style.maxHeight = `${curMaxHeightRef.current}px`;
        } else {
            dropdownRef.current.style.maxHeight = 0;
        };
    }, [dropdownVisible, dropdownRef.current, curMaxHeightRef.current]);

    React.useEffect(() => {
        const windowClickHandler = () => {
            if (dropdownVisible) setDropdownVisible(false);
        };

        window.addEventListener("click", windowClickHandler);

        return () => {
            try {
                window.removeEventListener("click", windowClickHandler);
            } catch {};
        };
    }, [dropdownVisible]);

    React.useEffect(() => {
        if (!props.onChange) return;

        if (selectedDateType !== "custom") {
            return props.onChange(prepareDate());
        } else {
            if (customDates?.start && customDates?.end) return props.onChange(prepareDate());
        };
    }, [selectedDateType, customDates]);

    return <div className="component__filters__filterByDate" onClick={e => {
        e.stopPropagation();
        setDropdownVisible(dv => !dv);
    }} style={{...(props.style ?? {})}}>
        <div ref={dropdownRef} className={`component__filters__filterByDate__dropdown ${dropdownVisible ? "component__filters__filterByDate__dropdown--active" : ""} ${selectedDateType === "custom" ? "component__filters__filterByDate__dropdown--custom" : ""}`} onClick={e => e.stopPropagation()}>
            <div className="component__filters__filterByDate__dropdown__left">
                {!props.disableAll && <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "all" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("all") || setDropdownVisible(false)}>{props.textAll ?? "All"}</div>}
                {props.enable1h && <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "1h" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("1h") || setDropdownVisible(false)}>{props.text24h ?? "Last 1 hour"}</div>}
                {props.enable4h && <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "4h" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("4h") || setDropdownVisible(false)}>{props.text24h ?? "Last 4 hours"}</div>}
                {!props.disable24h && <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "24h" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("24h") || setDropdownVisible(false)}>{props.text24h ?? "Last 24 hours"}</div>}
                {!props.disableToday && <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "today" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("today") || setDropdownVisible(false)}>{props.textToday ?? "Today"}</div>}
                <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "yesterday" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("yesterday") || setDropdownVisible(false)}>{props.textYesterday ?? "Yesterday"}</div>
                <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "7days" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("7days") || setDropdownVisible(false)}>{props.text7Days ?? "7 days"}</div>
                <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "30days" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("30days") || setDropdownVisible(false)}>{props.text30Days ?? "30 days"}</div>
                <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "thisMonth" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("thisMonth") || setDropdownVisible(false)}>{props.textThisMonth ?? "This month"}</div>
                <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "lastMonth" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("lastMonth") || setDropdownVisible(false)}>{props.textLastMonth ?? "Last month"}</div>
                <div className={`component__filters__filterByDate__dropdown__left__item ${selectedDateType === "custom" ? "component__filters__filterByDate__dropdown__left__item--active" : ""}`} onClick={() => setSelectedDateType("custom")}>{props.textCustom ?? "Custom"}</div>
            </div>
            <div className="component__filters__filterByDate__dropdown__right">
                <div className="component__filters__filterByDate__dropdown__right__input">
                    <p>Start date</p>
                    <input ref={startDateRef} type="date" />
                </div>
                <div className="component__filters__filterByDate__dropdown__right__input">
                    <p>End date</p>
                    <input ref={endDateRef} type="date" onKeyDown={handleKeyDown} />
                </div>

                <div className="component__filters__filterByDate__dropdown__right__btn" onClick={onApplyClick}>Apply</div>
            </div>
        </div>

        <div className="component__filters__filterByDate__left">
            <div className="component__filters__filterByDate__left__top">{props.text ?? "Date"}</div>
            <div className="component__filters__filterByDate__left__bottom">{(()=>{

                switch (selectedDateType) {
                    case "all": return "All";
                    case "1h": return "Last 1 hour";
                    case "4h": return "Last 4 hours";
                    case "24h": return "Last 24 hours";
                    case "today": return "Today";
                    case "yesterday": return "Yesterday";
                    case "thisMonth": return "This month";
                    case "lastMonth": return "Last month";
                };

                let curSpan = prepareDate();
                if (!curSpan) return null;
                if (selectedDateType === "custom" && (!curSpan?.start || !curSpan?.end)) return "Custom - no input"

                return `${curSpan.start.toDate().toLocaleDateString()} - ${curSpan.end.toDate().toLocaleDateString()}`
            })()}</div>
        </div>
        <div className="component__filters__filterByDate__right">
            <img src="/images/filters/filterByDate_icon.svg" />
        </div>
    </div>
};

export default FilterByDate;