import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import exceljs from "exceljs";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";

import FilterByDate from "../../../components/filters/FilterByDate";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../components/customComponents/Spinner";

import StyledButton from "../../../components/styledComponents/Button";

const AdminBotDetection = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();
    const [selectedIntegration, setSelectedIntegration] = React.useState();

    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getBotData = () => {
        let filters = [];

        if (dateFilters) {
            if (dateFilters.start && dateFilters.end) {
                filters.push(...[
                    {name: "createdAt", op: "dgeq", value: moment(dateFilters.start).toDate().getTime()},
                    {name: "createdAt", op: "dleq", value: moment(dateFilters.end).toDate().getTime()}
                ]);
            };
        };
        if (selectedIntegration !== null) {
            filters.push({name: "IntegrationType", op: "eq", value: selectedIntegration});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/botDetection/botCheck`,
            data: {
                filters,
                returnIDs: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            return setData(res.data);
        }).catch(() => {
            return setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const calculatePercent = (current, total) => {
        let tmp = `(0.00 %)`;
        current = Number(current);
        total = Number(total);

        if (isNaN(current) || isNaN(total)) return tmp;
        if (!current || !total) return tmp;

        return `(${Number((current / total) * 100).toFixed(2)} %)`;
    };

    const sortObjectTop5 = obj => {
        let keys = Object.keys(obj);
        keys = keys.sort((a, b) => {
            if (obj[a] > obj[b]) return -1;
            return 1;
        });

        let out = [];
        for (let key of keys.splice(0, 5)) {
            out.push(<p>{key} <span>(seen {obj[key]} times)</span></p>)
        };

        return out;
    };

    const exportData = (data, name) => {
        if (data.length === 0) return;

        let headers = Object.keys(data[0]);

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet(name);

        const convertTime = (time) => {
            let totalSeconds = Math.floor(time);
            let s = totalSeconds % 60;
            let m = Math.floor(totalSeconds / 60);

            let out = [];
            if (m) {
                out.push(`${m}m`);
                out.push(`${s}s`);
            } else if (s) {
                out.push(`${s}s`);
            };

            return out.join(" ");
        };

        sheet.addTable({
            name: name,
            "ref": "A1",
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: headers.map(hi => { return { name: hi } }),
            rows: data.map(c => {
                let out = [];
                for (let item of headers) {
                    if (item === "firstInteraction" || item === "sessionDuration") {
                        if (item === "firstInteraction") c[item] = c[item] ? c[item] / 1000 : null
                        out.push(convertTime(c[item]));
                    } else {
                        out.push(c[item]);
                    };
                };
                return out;
            })
        });
        sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        let fileName = `Export-bot-${name}`;
        if (dateFilters) {
            if (dateFilters.start) fileName += ` ${moment(dateFilters.start).format("DD-MM-YYYY")}`;
            if (dateFilters.end) fileName += ` ${moment(dateFilters.end).format("DD-MM-YYYY")}`;
        };
        fileName += ".xlsx";

        workbook.xlsx.writeBuffer().then(data => {
            let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);

        }).catch(() => null);
    };

    return <div className="route__admin__botDetection">
        <div className="route__admin__botDetection__filters">
            <AdvancedDropdown
                headline="Integration"
                data={[
                    {key: "all", name: "All", value: null},
                    ...supportedIntegrationsSelector.map(si => {
                        return {key: si.Type, name: si.Name, value: si.Type}
                    })
                ]}
                onChange={e => selectedIntegration !== e?.value && setSelectedIntegration(e.value)}
                selected={(()=>{
                    if (selectedIntegration === null) return 0;

                    return supportedIntegrationsSelector.indexOf(supportedIntegrationsSelector.find(si => si.Type === selectedIntegration)) + 1;
                })()}
            />
            <FilterByDate disableAll={true} onChange={e => setDateFilters(e)} />

            <StyledButton isDisabled={spinner} style={{height: "100%"}} onClick={getBotData}>Perform search</StyledButton>
        </div>

        {spinner ? <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} /> : <>
            {data ? data.status === "ok" ? <div className="route__admin__botDetection__data">
                <p>Total sessions: <span>{data.data.stats.totalRequests.length}</span></p>
                <br />

                {/* <p style={{display: "flex", alignItems: "center"}}>Sessions without interaction: <span>{data.data.stats.hasNoInteraction.length} {calculatePercent(data.data.stats.hasNoInteraction.length, data.data.stats.totalRequests.length)}</span> <img title="How many sessions haven't had any interactions. For an interaction to count, user must click on the page, scroll or hover over an element of the page." src="/images/question_mark.svg" /></p> */}
                <p>Zero-duration sessions: <span>{data.data.stats.sessionDurationLessThan_0s.length} {calculatePercent(data.data.stats.sessionDurationLessThan_0s.length, data.data.stats.totalRequests.length)}</span></p>
                <p>Sessions with duration &lt; 2s: <span>{data.data.stats.sessionDurationLessThan_2s.length} {calculatePercent(data.data.stats.sessionDurationLessThan_2s.length, data.data.stats.totalRequests.length)}</span></p>
                <p>Total suspicious sessions: <span>{data.data.suspicious.length} {calculatePercent(data.data.suspicious.length, data.data.stats.totalRequests.length)}</span>  {data.data.suspicious.length > 0 && <StyledButton style={{height: "100%"}} onClick={() => exportData(data.data.suspicious, "Bot-suspicious")}>Export</StyledButton>}</p>

                {Object.keys(data.data.repeatings.repeatingIPSuspicious).length > 0 && <>
                    <br />
                    <h4 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Top 5 suspicious IPs</h4>
                    {sortObjectTop5(data.data.repeatings.repeatingIPSuspicious)}
                </>}

                {Object.keys(data.data.repeatings.repeatingUserAgentSuspicious).length > 0 && <>
                    <br />
                    <h4 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Top 5 suspicious User Agents</h4>
                    {sortObjectTop5(data.data.repeatings.repeatingUserAgentSuspicious)}
                </>}
            </div> : <p>There was an error while fetching data</p> : null}
        </>}
    </div>
};

export default AdminBotDetection;