import axios from "axios";
import { getParamsFromURLObject } from "./urlModule";

let env = "prod";
let curStore = null;

export const setStore = st => curStore = st;
export const getStore = () => curStore;

export const setCSRF = () => {
  return axios({
    url: `${backendURL}/api/csrf`,
    method: "PUT",
    ...axiosConfig,
  })
    .then((res) => (axios.defaults.headers.common["X-XSRF-TOKEN"] = res.data))
    .catch(() => { });
};

const safeDecode = str => {
  try {
    return decodeURI(str);
  } catch {
    return str;
  };
};

export const parseData = (data) => {
  if (data === null || data === undefined) return data;
  switch (typeof data) {
    case "string": return safeDecode(data);
    case "number": return Number(data);
    case "object":
      if (data instanceof Array) {
        return data.map(item => parseData(item));
      } else {
        let final = {};
        Object.keys(data).forEach(item => {
          final[item] = parseData(data[item]);
        });
        return final;
      };
    case "boolean": return (String(data) === "true") ? true : false;
    default: return data;
  };
};

export const setAxiosDataHandler = () => {
  axios.interceptors.response.use((res) => {
    if (res.data.status === "ok") {
      res.data.data = parseData(res.data.data);
      return res;
    } else {
      return res;
    };
  }, err => {
    return Promise.reject(err);
  });

  let curParams = getParamsFromURLObject(String(window.location));
  if (curParams?.["_admin-viewbyid"]) {
    axios.interceptors.request.use(config => {
      config.headers["admin-takeover"] = curParams["_admin-viewbyid"];
      return config;
    });
  };
};

let backendURL = "http://localhost:5001";
let trackingURL = "http://localhost:5001/track";

let axiosConfig = {
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "https://lvh.me:3000",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  },
};

if (env === "prod") {
  backendURL = "https://api.scale-track.com:5000";
  trackingURL = "https://api.scale-track.com:5000/track";

  axiosConfig = {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://scale.track.com",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    },
  };
};

export {backendURL, trackingURL, axiosConfig};
export const genericError = {status: "error", data: "SERVER_ERROR"};