import React from 'react';
import { createRoot } from "react-dom/client";
import './index.css';

import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { getParamsFromURLObject } from './modules/urlModule';

import * as backendModule from "./modules/backendModule";

import App from './App';

const store = createStore(allReducers, composeWithDevTools());

backendModule.setAxiosDataHandler();
backendModule.setStore(store);

function entry() {
    let curURLParams = getParamsFromURLObject(String(window.location));
    if (curURLParams.redirect_oauth) {
        switch (curURLParams.redirect_oauth) {
            case "tiktok":
                if (curURLParams.auth_code && curURLParams.code && curURLParams.state) {
                    window.location = `${backendModule.backendURL}/integrations/tiktok/oauth/token?auth_code=${curURLParams.auth_code}&code=${curURLParams.code}&state=${curURLParams.state}`;
                    return;
                };
                break;
            default: break;
        };
    };
    
    const rootElem = createRoot(document.querySelector("#root"));
    rootElem.render(<Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>);
};

entry();