import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {animateBox} from "../../../modules/componentAnimation";
import YesNoModal from "../../../components/modals/YesNoModal";
import * as backendModule from "../../../modules/backendModule";
import { addHeaderRefreshAction, removeHeaderRefreshAction, updateTrackingProfilesTimestamp } from "../../../actions/siteFunctionsActions";

import Spinner from "../../../components/customComponents/Spinner";
import FilterBySearch from "../../../components/filters/FilterBySearch";

import AddIntegration_Facebook from "./Websites/Facebook";
import AddIntegration_Tiktok from "./Websites/Tiktok";
import AddIntegration_Mgid from "./Websites/Mgid";
import { AddIntegration_Facebook_checkAdLinks } from "./Websites/Facebook";
import AddIntegration_Scalelead from "./Websites/Scalelead";
import AddIntegration_Scalecrm from "./Websites/Scalecrm";

const UserIntegrations = () => {
    const [currentIntegrations, setCurrentIntegrations] = React.useState({
        "facebook": null,
        "tiktok": null,
        "mgid": null,
        "scalelead": null,
        "scalecrm": null
    });
    
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curDispatch = useDispatch();

    const checkFacebook = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook/checkIntegrationBulk`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["facebook"] = res.data;
                return tmp;
            });
        }).catch(() => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["facebook"] = backendModule.genericError;
                return tmp;
            });
        });
    };

    const checkTiktok = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/checkIntegrationBulk`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["tiktok"] = res.data;
                return tmp;
            });
        }).catch(() => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["tiktok"] = backendModule.genericError;
                return tmp;
            });
        });
    };

    const checkMgid = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/mgid/checkIntegration`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["mgid"] = res.data;
                return tmp;
            });
        }).catch(() => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["mgid"] = backendModule.genericError;
                return tmp;
            });
        });
    };

    const checkScalelead = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/scalelead/checkIntegration`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["scalelead"] = res.data;
                return tmp;
            });
        }).catch(() => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["scalelead"] = backendModule.genericError;
                return tmp;
            });
        });
    };

    const checkScalecrm = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/scalecrm/checkIntegration`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["scalecrm"] = res.data;
                return tmp;
            });
        }).catch(() => {
            setCurrentIntegrations(i => {
                let tmp = {...i};
                tmp["scalecrm"] = backendModule.genericError;
                return tmp;
            });
        });
    };

    const removeFacebookIntegration = (e, ID) => {
        if (!ID) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="If You remove the facebook integration, any stored data that depends on this integration will also be removed. This action is irreversible, are You really sure?"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/removeIntegration`,
                    data: {
                        IntegrationID: ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        curDispatch(updateTrackingProfilesTimestamp());
                        onIntegrationHandler();
                    } else {
                        args.errorMessage("There was an error while removing the integration.");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const removeTiktokIntegration = (e, ID) => {
        if (!ID) return;

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="If You remove the tiktok integration, any stored data that depends on this integration will also be removed. This action is irreversible, are You really sure?"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/removeIntegration`,
                    data: {
                        IntegrationID: ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        curDispatch(updateTrackingProfilesTimestamp());
                        onIntegrationHandler();
                    } else {
                        args.errorMessage("There was an error while removing the integration.");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const removeMgidIntegration = (e) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="If You remove the mgid integration, any stored data that depends on this integration will also be removed. This action is irreversible, are You really sure?"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/removeIntegration`,
                    data: {
                        IntegrationID: currentIntegrations["mgid"]?.data?.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        curDispatch(updateTrackingProfilesTimestamp());
                        onIntegrationHandler();
                    } else {
                        args.errorMessage("There was an error while removing the integration.");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const removeScaleleadIntegration = (e) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="If You remove the scalelead integration, any stored data that depends on this integration will also be removed. This action is irreversible, are You really sure?"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/removeIntegration`,
                    data: {
                        IntegrationID: currentIntegrations["scalelead"]?.data?.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        curDispatch(updateTrackingProfilesTimestamp());
                        onIntegrationHandler();
                    } else {
                        args.errorMessage("There was an error while removing the integration.");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const removeScalecrmIntegration = (e) => {
        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text="If You remove the scalelead integration, any stored data that depends on this integration will also be removed. This action is irreversible, are You really sure?"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.spinner(true);
                args.disabledAll(true);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/integrations/removeIntegration`,
                    data: {
                        IntegrationID: currentIntegrations["scalecrm"]?.data?.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        curDispatch(updateTrackingProfilesTimestamp());
                        onIntegrationHandler();
                    } else {
                        args.errorMessage("There was an error while removing the integration.");
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.spinner(false);
                    args.disabledAll(false);
                });
            }}
        />);
    };

    const onIntegrationHandler = () => {
        checkFacebook();
        checkTiktok();
        checkMgid();
        checkScalelead();
        checkScalecrm();
    };

    React.useEffect(() => {
        onIntegrationHandler();
        curDispatch(addHeaderRefreshAction(onIntegrationHandler));

        return () => curDispatch(removeHeaderRefreshAction(onIntegrationHandler));
    }, []);

    return <div className="route__user__integrations">
        <div className="route__user__integrations__search">
            <FilterBySearch />
        </div>

        <div className="route__user__integrations__list">
            {currentIntegrations["facebook"]?.status === "ok" && currentIntegrations["facebook"].data.map(fb => {
                return <div className="route__user__integrations__list__item">
                    <img src="/images/integrations/integration_facebook.svg" className="route__user__integrations__list__item__img" />
                    <p className="route__user__integrations__list__item__head">Facebook Ads</p>
                    <p className="route__user__integrations__list__item__text" style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>{currentIntegrations["facebook"] ? <>
                        {currentIntegrations["facebook"].status === "ok" ? <>
                            <span>{fb.FirstName} {fb.LastName}</span>
                            {fb.SelectedAccounts.length > 0 && <span style={{color: "#6c5dd3"}}>{String(fb.SelectedAccounts[0]?.ID).split("-")[1]}</span>}
                            {fb.SelectedAccounts.length > 0 && <span>{fb.SelectedAccounts[0]?.Name}</span>}
                        </> : "Not connected"}
                    </> : "Checking..."}</p>

                    {currentIntegrations["facebook"] ? <div className="route__user__integrations__list__item__btns">
                        <div className={`route__user__integrations__list__item__btns__btn ${currentIntegrations["facebook"].status === "ok" ? "route__user__integrations__list__item__btns__btn--connected" : ""} ${fb.MustReconnect ? "route__user__integrations__list__item__btns__btn--reconnect" : ""}`} onClick={e => {
                            if (currentIntegrations["facebook"].status === "ok" && fb.MustReconnect) {
                                return animateBox(e, <AddIntegration_Facebook ID={fb.ID} onChange={onIntegrationHandler} regenToken={true} />);
                            };
                            animateBox(e, <AddIntegration_Facebook ID={fb.ID} onChange={onIntegrationHandler} />);
                        }}>
                            <img src={(currentIntegrations["facebook"].status === "ok" && fb.MustReconnect) ? "/images/integrations/integration_reconnect.svg" : "/images/integrations/integration_link.svg"} />
                        </div>
                        {(currentIntegrations["facebook"].status === "ok" && !fb.MustReconnect) && <div className="route__user__integrations__list__item__btns__btn route__user__integrations__list__item__btns__btn--info" onClick={e => animateBox(e, <AddIntegration_Facebook_checkAdLinks ID={fb.ID} />)}>
                            <img src="/images/integrations/integration_info.svg" style={{width: "28px", height: "28px"}} />
                        </div>}
                        {currentIntegrations["facebook"].status === "ok" && <div className="route__user__integrations__list__item__btns__btn" onClick={e => removeFacebookIntegration(e, fb?.ID)}>
                            <img src="/images/integrations/integration_remove.svg" />
                        </div>}
                    </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
                </div>
            })}
            <div className="route__user__integrations__list__item">
                <img src="/images/integrations/integration_facebook.svg" className="route__user__integrations__list__item__img" />
                <p className="route__user__integrations__list__item__head">Facebook Ads</p>
                <p className="route__user__integrations__list__item__text">Not connected</p>

                {currentIntegrations["facebook"] ? <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn`} onClick={e => {
                        animateBox(e, <AddIntegration_Facebook onChange={onIntegrationHandler} />);
                    }}>
                        <img src={"/images/integrations/integration_link.svg"} />
                    </div>
                </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
            </div>

            {currentIntegrations["tiktok"]?.status === "ok" && currentIntegrations["tiktok"].data.map(fb => {
                return <div className="route__user__integrations__list__item">
                    <img src="/images/integrations/integration_tiktok.svg" className="route__user__integrations__list__item__img" />
                    <p className="route__user__integrations__list__item__head">Tiktok Ads</p>
                    <p className="route__user__integrations__list__item__text" style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>{currentIntegrations["tiktok"] ? <>
                        {currentIntegrations["tiktok"].status === "ok" ? <>
                            <span>{fb.FirstName} {fb.LastName}</span>
                            {fb.SelectedAccounts.length > 0 && <span style={{color: "#6c5dd3"}}>{String(fb.SelectedAccounts[0]?.ID).split("-")[1]}</span>}
                            {fb.SelectedAccounts.length > 0 && <span>{fb.SelectedAccounts[0]?.Name}</span>}
                        </> : "Not connected"}
                    </> : "Checking..."}</p>

                    {currentIntegrations["tiktok"] ? <div className="route__user__integrations__list__item__btns">
                        <div className={`route__user__integrations__list__item__btns__btn ${currentIntegrations["tiktok"].status === "ok" ? "route__user__integrations__list__item__btns__btn--connected" : ""} ${fb.MustReconnect ? "route__user__integrations__list__item__btns__btn--reconnect" : ""}`} onClick={e => {
                            if (currentIntegrations["tiktok"].status === "ok" && fb.MustReconnect) {
                                return animateBox(e, <AddIntegration_Tiktok ID={fb.ID} onChange={onIntegrationHandler} regenToken={true} />);
                            };
                            animateBox(e, <AddIntegration_Tiktok ID={fb.ID} onChange={onIntegrationHandler} />);
                        }}>
                            <img src={(currentIntegrations["tiktok"].status === "ok" && fb.MustReconnect) ? "/images/integrations/integration_reconnect.svg" : "/images/integrations/integration_link.svg"} />
                        </div>
                        {/* {(currentIntegrations["tiktok"].status === "ok" && !fb.MustReconnect) && <div className="route__user__integrations__list__item__btns__btn route__user__integrations__list__item__btns__btn--info" onClick={e => animateBox(e, <AddIntegration_Facebook_checkAdLinks ID={fb.ID} />)}>
                            <img src="/images/integrations/integration_info.svg" style={{width: "28px", height: "28px"}} />
                        </div>} */}
                        {currentIntegrations["tiktok"].status === "ok" && <div className="route__user__integrations__list__item__btns__btn" onClick={e => removeTiktokIntegration(e, fb?.ID)}>
                            <img src="/images/integrations/integration_remove.svg" />
                        </div>}
                    </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
                </div>
            })}
            <div className="route__user__integrations__list__item">
                <img src="/images/integrations/integration_tiktok.svg" className="route__user__integrations__list__item__img" />
                <p className="route__user__integrations__list__item__head">Tiktok Ads</p>
                <p className="route__user__integrations__list__item__text">Not connected</p>

                {currentIntegrations["tiktok"] ? <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn`} onClick={e => {
                        animateBox(e, <AddIntegration_Tiktok onChange={onIntegrationHandler} />);
                    }}>
                        <img src={"/images/integrations/integration_link.svg"} />
                    </div>
                </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
            </div>

            <div className="route__user__integrations__list__item">
                <img src="/images/integrations/integration_mgid.svg" className="route__user__integrations__list__item__img" />
                <p className="route__user__integrations__list__item__head">Mgid Ads</p>
                <p className="route__user__integrations__list__item__text">{currentIntegrations["mgid"] ? <>
                    {currentIntegrations["mgid"].status === "ok" ? `Connected, ${currentIntegrations["mgid"]?.data?.Active ? "active" : "inactive"}` : "Not connected"}
                </> : "Checking..."}</p>

                {currentIntegrations["mgid"] ? <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn ${currentIntegrations["mgid"].status === "ok" ? "route__user__integrations__list__item__btns__btn--connected" : ""}`} onClick={e => {
                        animateBox(e, <AddIntegration_Mgid onChange={onIntegrationHandler} />);
                    }}>
                        <img src="/images/integrations/integration_link.svg" />
                    </div>
                    {currentIntegrations["mgid"].status === "ok" && <div className="route__user__integrations__list__item__btns__btn" onClick={removeMgidIntegration}>
                        <img src="/images/integrations/integration_remove.svg" />
                    </div>}
                </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
            </div>

            <div className="route__user__integrations__list__item">
                <img src="/images/integrations/integration_scalelead.svg" className="route__user__integrations__list__item__img" style={{width: "auto"}} />
                <p className="route__user__integrations__list__item__head">Scale-Lead</p>
                <p className="route__user__integrations__list__item__text">{currentIntegrations["scalelead"] ? <>
                    {currentIntegrations["scalelead"].status === "ok" ? "Connected" : "Not connected"}
                </> : "Checking..."}</p>

                {currentIntegrations["scalelead"] ? <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn ${currentIntegrations["scalelead"].status === "ok" ? "route__user__integrations__list__item__btns__btn--connected" : ""}`} onClick={e => {
                        animateBox(e, <AddIntegration_Scalelead onChange={onIntegrationHandler} />);
                    }}>
                        <img src="/images/integrations/integration_link.svg" />
                    </div>
                    {currentIntegrations["scalelead"].status === "ok" && <div className="route__user__integrations__list__item__btns__btn" onClick={removeScaleleadIntegration}>
                        <img src="/images/integrations/integration_remove.svg" />
                    </div>}
                </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
            </div>

            <div className="route__user__integrations__list__item">
                <img src="/images/integrations/integration_scalecrm.svg" className="route__user__integrations__list__item__img" style={{width: "auto"}} />
                <p className="route__user__integrations__list__item__head">Scale-CRM</p>
                <p className="route__user__integrations__list__item__text">{currentIntegrations["scalecrm"] ? <>
                    {currentIntegrations["scalecrm"].status === "ok" ? "Connected" : "Not connected"}
                </> : "Checking..."}</p>

                {currentIntegrations["scalecrm"] ? <div className="route__user__integrations__list__item__btns">
                    <div className={`route__user__integrations__list__item__btns__btn ${currentIntegrations["scalecrm"].status === "ok" ? "route__user__integrations__list__item__btns__btn--connected" : ""}`} onClick={e => {
                        animateBox(e, <AddIntegration_Scalecrm onChange={onIntegrationHandler} />);
                    }}>
                        <img src="/images/integrations/integration_link.svg" />
                    </div>
                    {currentIntegrations["scalecrm"].status === "ok" && <div className="route__user__integrations__list__item__btns__btn" onClick={removeScalecrmIntegration}>
                        <img src="/images/integrations/integration_remove.svg" />
                    </div>}
                </div> : <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "50px", height: "50px"}} />}
            </div>
        </div>
    </div>
};

export default UserIntegrations;