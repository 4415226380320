import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import * as backendModule from "../../../../modules/backendModule";
import { getTrackingProfileImage } from "../../../../modules/miscModule";

import FilterByColumns from "../../../../components/filters/FilterColumns";
import FilterByDate from "../../../../components/filters/FilterByDate";

import StyledButton from "../../../../components/styledComponents/Button";
import StyledInput from "../../../../components/styledComponents/Input";
import AdvancedDropdown from "../../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";

const availableBreakdowns = [
    "Integration",
    "Offer",
    "Campaign",
    "Landing page",
    "Ad ID",
    "User gender",
    "User age"
];

const convertBreakdownsToColumns = b => {
    switch (b) {
        case "Integration": return "Date_IntegrationType";
        case "Ad ID": return "Date_IntegrationParams-stadid";
        case "User gender": return "Date_ClientData-Gender";
        case "Uer age": return "Date_ClientData-Age";
        default: return null;
    };
};
const isInternal = column => [
    "Offer",
    "Integration",
    "Campaign",
    "Landing page",
    "User age",
    "User gender"
].includes(column);

const BreakdownReport = () => {
    const [progress, setProgress] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [allSites, setAllSites] = React.useState();
    const [allCampaigns, setAllCampaigns] = React.useState();
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [additionalSpinner, setAdditionalSpinner] = React.useState(false);
    const [trackEvents, setTrackEvents] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();
    const [integrationFilters, setIntegrationFilters] = React.useState(undefined);
    const [genderFilters, setGenderFilters] = React.useState(undefined);
    const [campaignFilters, setCampaignFilters] = React.useState(undefined);
    const [offerFilters, setOfferFilters] = React.useState(undefined);
    const [adidFilters, setAdidFilters] = React.useState("");
    const [breakdowns, setBreakdowns] = React.useState(["Offer"]);
    const [ageFilters, setAgeFilters] = React.useState("");
    const [filtersApplied, setFiltersApplied] = React.useState(false);
    const [orders, setOrders] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const objectDeepEqual = (x, y) => {
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
            ok(x).length === ok(y).length &&
            ok(x).every(key => objectDeepEqual(x[key], y[key]))
        ) : (x === y);
    };

    const prepareTableData = (data, column) => {
        if (column?.endsWith?.(".lastDate") && column?.startsWith?.("@")) {
            if (data === 0) return "Never";
            if (moment(data).isValid()) return moment(data).toDate().toLocaleString();
        };
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPC":
            case "CPA":
            case "CPAO":
            case "ADP":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "DR":
            case "BACR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(2)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    const getTrackEvents = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/getAllForUser`,
            ...backendModule.axiosConfig
        }).then(res => {
            setTrackEvents(res.data);
        }).catch(() => {
            setTrackEvents(backendModule.genericError);
        });
    };

    const getAllCampaigns = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                limit: null,
                allUsers: true
            },
            ...backendModule.axiosConfig
        }).then(res => setAllCampaigns(res.data)).catch(() => setAllCampaigns(backendModule.genericError));
    };

    const getAllOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllOffers(res.data);
        }).catch(() => setAllOffers(backendModule.genericError));
    };

    const getAllSites = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllSites(res.data);
        }).catch(() => setAllSites(backendModule.genericError));
    };

    const getSpentData = async (data) => {
        let finalSpentData = [];
        let finalRevenueData = [];
        let finalProfitData = [];
        let finalCPAData = [];
        let finalCPAOData = [];
        let finalROIData = [];
        let finalROASData = [];

        setAdditionalSpinner(true);
        setProgress({cur: 0, max: data.constraints.length});

        let toWait = [];
        for (let constraint of data.constraints) {
            toWait.push(new Promise(async r => {
                let filters = [];
                if (constraint.Date_CampaignID !== undefined) {
                    filters.push({name: "CampaignID", op: "eq", value: constraint.Date_CampaignID});
                };
                if (constraint["Date_IntegrationParams-stadid"] !== undefined) {
                    filters.push({name: "IntegrationParams.stadid", op: "eq", value: constraint["Date_IntegrationParams-stadid"]});
                };
                if (constraint["Date_IntegrationType"] !== undefined) {
                    filters.push({name: "IntegrationType", op: "eq", value: constraint["Date_IntegrationType"]});
                };
                if (constraint["Date_SiteID"] !== undefined) {
                    filters.push({name: "SiteID", op: "eq", value: constraint["Date_SiteID"]});
                };
    
                // age and gender
                if (constraint["Date_ClientData-Age"] !== undefined) {
                    filters.push({name: "Age_IN", op: "eq", value: constraint["Date_ClientData-Age"]});

                    if (constraint["Date_ClientData-Age"]) {
                        let ageStr = String(constraint["Date_ClientData-Age"]);
                        if (ageStr.includes("-")) {
                            let [as, ae] = ageStr.split("-").map(a => Number(a));
    
                            filters.push({name: "ClientData.Age", op: "geq", value: as});
                            filters.push({name: "ClientData.Age", op: "leq", value: ae});
                        };
                    };
                };
                if (constraint["Date_ClientData-Gender"] !== undefined) {
                    filters.push({name: "Gender_IN", op: "eq", value: constraint["Date_ClientData-Gender"]});
                    
                    if (constraint["Date_ClientData-Gender"]) {
                        filters.push({name: "ClientData.Gender", op: "eq", value: constraint["Date_ClientData-Gender"]});
                    };
                };
    
                if (integrationFilters !== null && integrationFilters !== undefined) filters.push({ name: "IntegrationType", op: "eq", value: integrationFilters });
                if (genderFilters) filters.push({ name: "ClientData.Gender", op: "eq", value: genderFilters });
                if (adidFilters) filters.push({ name: "IntegrationParams.stadid", op: "eq", value: adidFilters });
                if (campaignFilters) filters.push({ name: "CampaignID", op: "eq", value: campaignFilters });
                if (offerFilters) {
                    let curSites = allSites.data.filter(s => s.OfferID === offerFilters).map(s => s.ID);
                    filters.push({ name: "SiteID", op: "in", value: curSites.length === 0 ? [null] : curSites });
                };
                if (ageFilters) {
                    if (ageFilters.startsWith("<")) {
                        let ageTmp = ageFilters.substring(1, ageFilters.length);
                        filters.push({ name: "ClientData.Age", op: "leq", value: Number(ageTmp) });
                    } else if (ageFilters.endsWith(">")) {
                        let ageTmp = ageFilters.substring(0, ageFilters.length - 1);
                        filters.push({ name: "ClientData.Age", op: "geq", value: Number(ageTmp) });
                    } else {
                        let ageTmp = ageFilters.split("-");
                        filters.push({ name: "ClientData.Age", op: "geq", value: Number(ageTmp[0]) });
                        filters.push({ name: "ClientData.Age", op: "leq", value: Number(ageTmp[1]) });
                    };
                };
    
                // finally, add the date
                filters.push(...[
                    { name: "createdAt", op: "pdgeq", value: dateFilters?.start?.toDate().getTime() },
                    { name: "createdAt", op: "pdleq", value: dateFilters?.end?.toDate().getTime() }
                ]);
    
                let finalData = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                    data: {
                        allUsers: true,
                        TableHeaders: ["Spent", "Spent_IN"],
                        filters,
                        skipIntegrationDataPull: false
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);
    
                let totalSpent = {...constraint, Value: 0};
                if (finalData.status === "ok") {
                    if (finalData.data.Integrations) {
                        for (let i of Object.keys(finalData.data.Integrations)) {
                            let finalVal = finalData.data.Integrations[i]?.TableData ?? {};
                            if (finalVal?.["Spent"] || finalVal?.["Spent_IN"]) {
                                totalSpent.Value += Number(finalVal?.["Spent_IN"] || finalVal?.["Spent"]);
                            };
                        };
                    };
                };
    
                let totalConversions = {...constraint, Value: getDataValue("Conversions", constraint, false)};
                let totalApproved = {...constraint, Value: getDataValue("Approved", constraint, false)};
                let totalRevenue = {...constraint, Value: getDataValue("Revenue", constraint, false)};
                let totalProfit = {...constraint, Value: totalSpent.Value - totalRevenue.Value};
    
                let totalCPA = {...constraint, Value: (totalConversions?.Value && totalSpent?.Value) ? (totalSpent.Value / totalConversions.Value) : 0};
                let totalCPAO = {...constraint, Value: totalApproved?.Value ? totalSpent.Value / totalApproved.Value : 0};
                let totalROI = {...constraint, Value: (totalProfit?.Value && totalSpent?.Value) ? ((totalProfit.Value / totalSpent.Value) * 100) : 0};
                let totalROAS = {...constraint, Value: (totalRevenue?.Value && totalSpent?.Value) ? ((totalRevenue.Value / totalSpent.Value) * 100) : 0};
    
                finalSpentData.push(totalSpent);
                finalRevenueData.push(totalRevenue);
                finalProfitData.push(totalProfit);
                finalCPAData.push(totalCPA);
                finalCPAOData.push(totalCPAO);
                finalROIData.push(totalROI);
                finalROASData.push(totalROAS);

                setProgress(p => {
                    return {cur: p.cur+=1, max: p.max};
                });
                r();
            }));
        };

        await Promise.allSettled(toWait);
        setProgress();
        setData({status: "ok", data: {
            ...data,
            stats: {
                ...data.stats,
                "Spent": finalSpentData,
                "Profit": finalProfitData,
                "Revenue": finalRevenueData,
                "CPA": finalCPAData,
                "CPAO": finalCPAOData,
                "ROI": finalROIData,
                "ROAS": finalROASData
            }
        }});
        setAdditionalSpinner(false);
    };

    const getInitialTrackDataPull = async () => {
        setSpinner(true);
        if (!dateFilters) {
            setSpinner(false);
            return setData(backendModule.genericError);
        };
        if (!dateFilters?.start || !dateFilters?.end) {
            setSpinner(false);
            return setData(backendModule.genericError);
        };
        if (breakdowns.filter(b => b).length === 0) {
            setSpinner(false);
            return setData(backendModule.genericError);
        };

        let filters = [
            { name: "createdAt", op: "pdgeq", value: dateFilters?.start?.toDate().getTime() },
            { name: "createdAt", op: "pdleq", value: dateFilters?.end?.toDate().getTime() }
        ];
        if (integrationFilters !== null && integrationFilters !== undefined) filters.push({ name: "IntegrationType", op: "eq", value: integrationFilters });
        if (genderFilters) filters.push({ name: "ClientData.Gender", op: "eq", value: genderFilters });
        if (adidFilters) filters.push({ name: "IntegrationParams.stadid", op: "eq", value: adidFilters });
        if (campaignFilters) filters.push({ name: "CampaignID", op: "eq", value: campaignFilters });
        if (offerFilters) {
            let curSites = allSites.data.filter(s => s.OfferID === offerFilters).map(s => s.ID);
            filters.push({ name: "SiteID", op: "in", value: curSites.length === 0 ? [null] : curSites });
        };
        if (ageFilters) {
            if (ageFilters.startsWith("<")) {
                let ageTmp = ageFilters.substring(1, ageFilters.length);
                filters.push({ name: "ClientData.Age", op: "leq", value: Number(ageTmp) });
            } else if (ageFilters.endsWith(">")) {
                let ageTmp = ageFilters.substring(0, ageFilters.length - 1);
                filters.push({ name: "ClientData.Age", op: "geq", value: Number(ageTmp) });
            } else {
                let ageTmp = ageFilters.split("-");
                filters.push({ name: "ClientData.Age", op: "geq", value: Number(ageTmp[0]) });
                filters.push({ name: "ClientData.Age", op: "leq", value: Number(ageTmp[1]) });
            };
        };

        let headers = [];
        for (let item of breakdowns) {
            if (item === "Integration") headers.push("Date_IntegrationType");
            if (item === "Offer") headers.push("Date_SiteID");
            if (item === "Landing page") headers.push("Date_SiteID");
            if (item === "Campaign") headers.push("Date_CampaignID");
            if (item === "Ad ID") headers.push("Date_IntegrationParams-stadid");
            if (item === "User gender") headers.push("Date_ClientData-Gender");
            if (item === "User age") headers.push("Date_ClientData-Age");
        };
        headers.push("Visits", "Conversions", "CR", "Processed", "Approved", "AR", "ADP", "DR");
        headers = [...new Set(headers)];
        if (!headers.find(h => h.startsWith("Date_"))) {
            setSpinner(false);
            return setData(backendModule.genericError);
        };
        let expectedGroups = headers.filter(h => h.startsWith("Date_"));
        let finalData = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
            data: {
                allUsers: true,
                trackGroupByDate: true,
                TableHeaders: headers,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);
        if (finalData?.status !== "ok") {
            setSpinner(false);
            return setData(finalData);
        };
        let finalHeaders = [];
        for (let item of breakdowns) {
            if (!item) continue;
            finalHeaders.push(item);
        };
        finalHeaders.push("Visits", "Revenue", "Conversions", "CR", "Processed", "Approved", "AR", "CPA", "CPAO", "ADP", "Spent", "Revenue", "Profit", "ROI", "ROAS", "DR");
        let hTmp = [...finalHeaders];
        for (let i=0; i<=hTmp.length-1; i++) {
            if (finalHeaders.filter(f => f === hTmp[i]).length > 1) {
                // got 2
                let found = false;
                finalHeaders = finalHeaders.filter((f) => {
                    if (found) return true;
                    if (f === hTmp[i]) {
                        found = true;
                        return false;
                    };
                    return true;
                });
            };
        };
        let outData = {
            stats: finalData?.data?.TableData ?? {},
            headers: [...new Set(finalHeaders)],
            constraints: []
        };
        outData.campaigns = allCampaigns.data.map(c => {
            return {
                ID: c.ID,
                Name: c.CampaignName
            };
        });
        outData.sites = allSites.data.map(s => {
            return {
                ID: s.ID,
                Name: `${s.SiteName} (${s.Country})`
            };
        });
        outData.offers = allOffers.data.map(o => {
            let sites = allSites.data.filter(s => s.OfferID === o.ID).map(s => s.ID);
            return {
                ID: o.ID,
                Name: `${o.OfferName} (${o.Country}, ${o.OfferType})`,
                Sites: sites
            };
        });

        for (let key of Object.keys(outData.stats)) {
            for (let elem of outData.stats[key]) {
                if (elem?.["Date_SiteID"]) {
                    elem.OfferID = null;
                    let foundOffer = outData.offers.find(o => o.Sites.includes(elem?.["Date_SiteID"]));
                    if (foundOffer) elem.OfferID = foundOffer.ID;
                };

                let searchHeaders = Object.keys(elem).filter(e => e.startsWith("Date_"));
                let tmpTest = {};

                for (let k of searchHeaders) {
                    tmpTest[k] = elem[k];
                };
                let testKeys = Object.keys(tmpTest);
                if (testKeys.length === expectedGroups.length && testKeys.length !== 0) {
                    if (!outData.constraints.find(o => objectDeepEqual(o, tmpTest))) {
                        outData.constraints.push(tmpTest);
                    };
                };
            };
        };

        setData({ status: "ok", data: outData });
        setSpinner(false);
    };

    const getInternalValue = (column, values) => {
        if (column === "Offer") {
            let foundOffer = data.data.offers.find(o => o.Sites.includes(values["Date_SiteID"]));
            return foundOffer ? foundOffer.Name : <span style={{ color: "gray" }}>(Not found) {values["Date_SiteID"]}</span>;
        } else if (column === "Campaign") {
            let foundCampaign = data.data.campaigns.find(c => c.ID === values["Date_CampaignID"]);
            return foundCampaign ? foundCampaign.Name : <span style={{ color: "gray" }}>(Not found) {values["Date_CampaignID"]}</span>;
        } else if (column === "Landing page") {
            let foundPage = data.data.sites.find(s => s.ID === values["Date_SiteID"]);
            return foundPage ? foundPage.Name : <span style={{ color: "gray" }}>(Not found) {values["Date_SiteID"]}</span>;
        } else if (column === "User age") {
            let data = values["Date_ClientData-Age"];
            return data ? data : <span style={{ color: "gray" }}>(Not found)</span>;
        } else if (column === "User gender") {
            let data = values["Date_ClientData-Gender"];
            return data ? data : <span style={{ color: "gray" }}>(Not found)</span>;
        };
        return <span style={{ color: "gray" }}>(Not found)</span>;
    };

    const getDataValue = (column, constraints, processOutput = true) => {
        if (data?.status !== "ok") {
            if (!processOutput) return 0;
            return "?";
        };

        for (let item of (data.data.stats?.[column]) ?? []) {
            let found = true;
            for (let key of Object.keys(constraints)) {
                if (constraints[key] !== item[key]) {
                    found = false;
                    break;
                };
            };
            if (!found) continue;

            if (!processOutput) return item?.Value;
            return prepareTableData(item?.Value, column);
        };

        if (!processOutput) return 0;
        return "?";
    };

    const getIntegrationName = integrationType => {
        let foundIntegration = supportedIntegrationsSelector.find(i => String(i.Type) === String(integrationType));
        if (!foundIntegration) return "?";

        return <div className="route__reports_breakdown__filters__img">
            <img src={getTrackingProfileImage(foundIntegration.NamedType)} />
            <span>{`${foundIntegration.Name.charAt(0).toUpperCase() + foundIntegration.Name.substring(1)}`}</span>
        </div>;
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        const getElementText = elem => {
            if (Array.isArray(elem?.props?.children)) {
                return getElementText(elem?.props?.children[elem.props.children.length - 1]);
            };

            return String(elem?.props?.children ?? elem);
        };

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            let a1Compare = a1?.text;
            if (a1?.text?.["$$typeof"]) a1Compare = getElementText(a1.text);
            let b1Compare = b1?.text;
            if (b1?.text?.["$$typeof"]) b1Compare = getElementText(b1.text);

            a1Compare = String(a1Compare);
            b1Compare = String(b1Compare);

            if (a1Compare.endsWith("%") || a1Compare.endsWith(currencySignSelector)) a1Compare = Number(a1Compare.split(" ")[0]);
            if (b1Compare.endsWith("%") || b1Compare.endsWith(currencySignSelector)) b1Compare = Number(b1Compare.split(" ")[0]);

            if (!isNaN(a1Compare) && !isNaN(b1Compare)) {
                a1Compare = Number(a1Compare);
                b1Compare = Number(b1Compare);
                return a1Compare > b1Compare ? asc : desc;
            } else {
                return a1Compare > b1Compare ? asc : desc;
            };
        });
    };

    React.useEffect(() => {
        if (filtersApplied) setFiltersApplied(false);
        if (breakdowns[breakdowns.length - 1] !== "" && breakdowns.length !== availableBreakdowns.length) setBreakdowns(b => [...b, ""]);
    }, [breakdowns, dateFilters, integrationFilters, genderFilters, adidFilters, campaignFilters, offerFilters, ageFilters]);

    React.useEffect(() => {
        if (filtersApplied) getInitialTrackDataPull();
    }, [filtersApplied]);

    React.useEffect(() => {
        if (data?.status === "ok") {
            if (data?.data?.stats) {
                if (!data?.data?.stats?.Spent) {
                    getSpentData(data.data);
                };
            };
        };
    }, [data]);

    React.useEffect(() => {
        getAllOffers();
        getAllSites();
        getAllCampaigns();
        getTrackEvents();
    }, []);

    if (!allOffers || !allSites || !allCampaigns || !trackEvents) return <Spinner color={themeSelector === "dark" ? "white" : "black"} />
    if (allOffers?.status !== "ok" || allSites?.status !== "ok" || allCampaigns?.status !== "ok") return <p>There was an error while fetching initial data!</p>
    return <div className="route__reports_breakdown">
        {progress && <div className="route__reports_breakdown__progress">
            <div className="route__reports_breakdown__progress__line" style={{width: `${100 / progress.max * progress.cur}%`}}></div>
        </div>}
        <div className="route__reports_breakdown__breakdowns">
            {breakdowns.map((b, bIdx, bAll) => {
                let finalBreakdowns = availableBreakdowns.filter(ab => {
                    if (bIdx === 0) return true;

                    for (let i = 0; i <= bIdx - 1; i++) {
                        if (bAll[i] === ab) return false;
                    };

                    return true;
                });
                return <AdvancedDropdown
                    headline={<>
                        <span>Break by</span>
                        {b && <StyledButton
                            style={{ height: "17px", fontSize: "12px", padding: "2px 10px" }}
                            onClick={e => {
                                e.stopPropagation();
                                setBreakdowns(sb => {
                                    let tmp = [...sb];
                                    tmp = tmp.filter((_, idx) => idx < bIdx);
                                    return tmp;
                                });
                            }}
                        >Remove</StyledButton>}
                    </>}
                    data={finalBreakdowns.map(ab => {
                        return { key: ab, name: ab, value: ab };
                    })}
                    onChange={e => {
                        if (!e?.value) return;
                        setBreakdowns(sb => {
                            let tmp = [...sb];
                            tmp[bIdx] = e?.value;

                            tmp = tmp.filter((_, idx) => idx <= bIdx);
                            return tmp;
                        });
                    }}
                    selected={(() => {
                        if (!b) return null;
                        return finalBreakdowns.indexOf(b);
                    })()}
                />
            })}
        </div>

        <div className="route__reports_breakdown__filters">
            <FilterByDate disable24h={true} disableAll={true} defaultValue="yesterday" style={{ width: "300px" }} onChange={e => setDateFilters(e)} />
            <AdvancedDropdown
                style={{ width: "300px" }}
                headline="Integration"
                showSearch={true}
                data={[
                    { key: "any", name: "Any", value: undefined },
                    ...supportedIntegrationsSelector.map(item => {
                        return {
                            key: item.Name,
                            name: <div className="route__reports_breakdown__filters__img">
                                <img src={getTrackingProfileImage(item.NamedType)} />
                                <span>{`${item.Name.charAt(0).toUpperCase() + item.Name.substring(1)}`}</span>
                            </div>,
                            value: item.Type
                        }
                    })
                ]}
                onChange={e => integrationFilters !== e?.value && setIntegrationFilters(e?.value)}
                selected={(() => {
                    if (integrationFilters === undefined) return 0;
                    let out = [undefined, ...supportedIntegrationsSelector.map(i => i.Type)];
                    return out.indexOf(integrationFilters);
                })()}
            />
            <AdvancedDropdown
                style={{ width: "300px" }}
                headline="Campaign"
                showSearch={true}
                data={[
                    { key: "any", name: "Any", value: undefined },
                    ...allCampaigns.data.map(c => {
                        return { key: c.ID, name: c.CampaignName, value: c.ID }
                    })
                ]}
                onChange={e => e?.value !== campaignFilters && setCampaignFilters(e?.value)}
                selected={(() => {
                    if (campaignFilters === undefined) return 0;
                    return allCampaigns.data.indexOf(allCampaigns.data.find(c => c.ID === campaignFilters)) + 1;
                })()}
            />
            <AdvancedDropdown
                style={{ width: "300px" }}
                headline="Offer"
                showSearch={true}
                data={[
                    { key: "any", name: "Any", value: undefined },
                    ...allOffers.data.map(c => {
                        return { key: c.ID, name: `${c.OfferName} (${c.Country}, ${c.OfferType})`, value: c.ID }
                    })
                ]}
                onChange={e => e?.value !== offerFilters && setOfferFilters(e?.value)}
                selected={(() => {
                    if (offerFilters === undefined) return 0;
                    return allOffers.data.indexOf(allOffers.data.find(c => c.ID === offerFilters)) + 1;
                })()}
            />
            <AdvancedDropdown
                style={{ width: "300px" }}
                headline={"Gender"}
                showSearch={true}
                data={[
                    { key: "any", name: "Any", value: undefined },
                    { key: "Male", name: "Male", value: "Male" },
                    { key: "Female", name: "Female", value: "Female" }
                ]}
                onChange={e => e?.value !== genderFilters && setGenderFilters(e?.value)}
                selected={(() => {
                    if (genderFilters === undefined) return 0;
                    return [undefined, "Male", "Female"].indexOf(genderFilters);
                })()}
            />
            <AdvancedDropdown
                style={{ width: "300px" }}
                headline={"Age"}
                showSearch={true}
                data={[
                    { key: "any", name: "Any", value: undefined },
                    { key: "0-18", name: "0 - 18", value: "<18" },
                    { key: "18-24", name: "18 - 24", value: "18-24" },
                    { key: "25-34", name: "25 - 34", value: "25-34" },
                    { key: "35-44", name: "35 - 44", value: "35-44" },
                    { key: "45-64", name: "45 - 64", value: "45-64" },
                    { key: ">65", name: "65 +", value: ">65" },
                ]}
                onChange={e => ageFilters !== e?.value && setAgeFilters(e?.value)}
                selected={(() => {
                    if (!ageFilters) return 0;
                    switch (ageFilters) {
                        case "<18": return 1;
                        case "18-24": return 2;
                        case "25-34": return 3;
                        case "35-44": return 4;
                        case "45-64": return 5;
                        case ">65": return 6;
                        default: return 0;
                    };
                })()}
            />
            <StyledInput placeholder="Ad ID" alternateStyle={true} value={adidFilters} onChange={e => setAdidFilters(e?.target?.value)} />
        </div>

        {!filtersApplied && <div className="route__reports_breakdown__applyFilters">
            <StyledButton onClick={() => setFiltersApplied(true)}>Apply filters</StyledButton>
        </div>}

        <div className={`route__reports_breakdown__table ${data?.status === "ok" ? "route__reports_breakdown__table--ok" : ""}`}>
            {data ? (spinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} /> : (data?.status === "ok" ? <FilteredCustomTable
                orderCB={o => setOrders(o)}
                theme={themeSelector}
                accent="#6C5DD3"
                style={{
                    width: "auto",
                    minWidth: "100%",
                    columnGap: "40px"
                }}
                customColumns={(new Array(data.data.headers.length)).fill("max-content")}
                headers={data.data.headers}
                data={(() => {
                    let out = [];
                    let ID = 0;
                    for (let constraint of data.data.constraints) {
                        ID++;
                        let tmp = [];

                        for (let h of data.data.headers) {
                            // parse each header as its own element
                            if (isInternal(h)) {
                                if (h === "Integration") {
                                    tmp.push({ keyID: String(ID), type: "text", text: getIntegrationName(constraint["Date_IntegrationType"]) });
                                } else if (h === "User gender") {
                                    tmp.push({ keyID: String(ID), type: "text", text: getInternalValue(h, constraint) });
                                } else if (h === "User age") {
                                    tmp.push({ keyID: String(ID), type: "text", text: getInternalValue(h, constraint) });
                                } else {
                                    tmp.push({ keyID: String(ID), type: "text", text: getInternalValue(h, constraint) });
                                };
                            } else {
                                let newH = convertBreakdownsToColumns(h);
                                if (newH) {
                                    tmp.push({ keyID: String(ID), type: "text", text: constraint[newH] ?? <span style={{ color: "gray" }}>(Not found)</span> });
                                } else {
                                    let finalVal = { keyID: String(ID), type: "text", text: getDataValue(h, constraint) };
                                    if (finalVal.text === "?" && additionalSpinner) {
                                        delete finalVal.text;
                                        finalVal.type = "spinner";
                                        finalVal.color = "white";
                                        finalVal.size = "16px";
                                    };
                                    tmp.push(finalVal);
                                };
                            };
                        };

                        out.push(tmp);
                    };

                    if (out.length === 0) out.push([{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }]);
                    if (orders) out = orderData(out, data.data.headers);
                    return out;
                })()}
            /> : <p>There was an error while preparing data!</p>)) : spinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} /> : <p>Click on "Apply Filters" to fetch new data.</p>}
        </div>
    </div>
};

export default BreakdownReport;