import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import exceljs from "exceljs";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import { animateBox } from "../../../modules/componentAnimation";
import { arrayToCSVString } from "../../../modules/csvParserModule";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";
import StyledButton from "../../styledComponents/Button";

import PreviewImageModal from "../PreviewImageModal";
import YesNoModal from "../YesNoModal";

import { ReactComponent as InfoTab_Img } from "./images/orders_info.svg";
import { ReactComponent as JourneyTab_Img } from "./images/orders_journey.svg";

const SessionModal = props => {
    const [data, setData] = React.useState();
    const [dataSum, setDataSum] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [globalSpinner, setGlobalSpinner] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState();
    const [orders, setOrders] = React.useState();

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curDefer = useDefer();
    const orderDefer = useDefer();
    const curOnScreen = useOnScreen();

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;
    const wrapTableItemWithDots = item => <span style={{
        padding: "0 50px 0 0px",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "block"
    }}>
        {item}
    </span>;

    const getCampaignIDs = () => {
        return Array.isArray(props.item) ? props.item : [props.item.ID];
    };

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = (ts) => {
        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    { name: "LocationData.country", op: "like", value: props.search },
                    { name: "LocationData.city", op: "like", value: props.search },
                    { name: "NetworkData.IP", op: "like", value: props.search },
                    { name: "NetworkData.Referrer", op: "like", value: props.search },
                    { name: "DeviceData.UserAgent", op: "like", value: props.search },
                    { name: "DeviceData.Device.os.name", op: "like", value: props.search },
                    { name: "DeviceData.Device.client.name", op: "like", value: props.search },
                    { name: "DeviceData.Device.device.type", op: "like", value: props.search },

                    { name: "IntegrationParams.st_source", op: "like", value: props.search }
                ]
            })
        };

        if (Array.isArray(props.filters)) filters.push(...props.filters);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllSessions`,
            data: {
                filters,
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                limit: 20,
                orders: orders ? [orders] : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.axiosConfig);
        }).finally(() => {
            if (timestampRef.current !== ts) return;
            setGlobalSpinner(false);
        });
    };

    const continueData = (ts) => {
        setCanPaginate(false);
        if (!data) return;
        if (data.status !== "ok") return;
        if (data.data.lnegth === 0) return;

        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    { name: "LocationData.country", op: "like", value: props.search },
                    { name: "LocationData.city", op: "like", value: props.search },
                    { name: "NetworkData.IP", op: "like", value: props.search },
                    { name: "NetworkData.Referrer", op: "like", value: props.search },
                    { name: "DeviceData.UserAgent", op: "like", value: props.search },
                    { name: "DeviceData.Device.os.name", op: "like", value: props.search },
                    { name: "DeviceData.Device.client.name", op: "like", value: props.search },
                    { name: "DeviceData.Device.device.type", op: "like", value: props.search }
                ]
            })
        };
        if (Array.isArray(props.filters)) filters.push(...props.filters);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllSessions`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                limit: 20,
                offset: data.data.length,
                filters,
                orders: orders ? [orders] : null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (timestampRef.current !== ts) return;
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
                if (res.data.data.length === 20) {
                    setCanPaginate(true);
                } else {
                    setCanPaginate(false);
                };
            };
        }).catch(() => null).finally(() => {
            if (timestampRef.current !== ts) return;
            setSpinner(false);
            setGlobalSpinner(false);
        });
    };

    const getSums = () => {
        const filters = [];

        if (props.search) {
            filters.push({
                or: [
                    { name: "LocationData.country", op: "like", value: props.search },
                    { name: "LocationData.city", op: "like", value: props.search },
                    { name: "NetworkData.IP", op: "like", value: props.search },
                    { name: "NetworkData.Referrer", op: "like", value: props.search },
                    { name: "DeviceData.UserAgent", op: "like", value: props.search },
                    { name: "DeviceData.Device.os.name", op: "like", value: props.search },
                    { name: "DeviceData.Device.client.name", op: "like", value: props.search },
                    { name: "DeviceData.Device.device.type", op: "like", value: props.search },

                    { name: "IntegrationParams.st_source", op: "like", value: props.search }
                ]
            })
        };

        if (Array.isArray(props.filters)) filters.push(...props.filters);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getSessionsSum`,
            data: {
                filters,
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs()
            },
            ...backendModule.axiosConfig
        }).then(res => setDataSum(res.data)).catch(() => setDataSum(backendModule.genericError));
    };

    const convertTimeToString = time => {
        let seconds = +time;
        let minutes = 0;
        let hours = 0;

        while (seconds >= 60) {
            seconds -= 60;
            minutes++;
        };
        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        };

        if (hours) return `${hours}h : ${minutes}m : ${seconds}s`;
        if (minutes) return `${minutes}m : ${seconds}s`;
        return `${seconds}s`;
    };

    const removeIPPadding = ip => {
        if (!ip) return ip;

        ip = String(ip);
        if (ip.startsWith("::ffff:")) ip = ip.replace("::ffff:", "");

        return ip;
    };

    const performExport = e => {
        animateBox(e, <YesNoModal
            heading="Session export"
            text="You are about to export all sessions for selected filters. This will take some time. Are You sure?"
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.spinner(true);
                args.disabledAll(true);

                const filters = [];
                if (props.search) {
                    filters.push({
                        or: [
                            { name: "LocationData.country", op: "like", value: props.search },
                            { name: "LocationData.city", op: "like", value: props.search },
                            { name: "NetworkData.IP", op: "like", value: props.search },
                            { name: "NetworkData.Referrer", op: "like", value: props.search },
                            { name: "DeviceData.UserAgent", op: "like", value: props.search },
                            { name: "DeviceData.Device.os.name", op: "like", value: props.search },
                            { name: "DeviceData.Device.client.name", op: "like", value: props.search },
                            { name: "DeviceData.Device.device.type", op: "like", value: props.search }
                        ]
                    })
                };
                if (Array.isArray(props.filters)) filters.push(...props.filters);

                let data = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getAllSessions`,
                    data: {
                        IntegrationType: props.integration,
                        CampaignIDs: getCampaignIDs(),
                        limit: null,
                        filters,
                        orders: orders ? [orders] : null
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);
                if (data.status === "error") {
                    args.spinner(false);
                    args.disabledAll(false);
                    args.errorMessage("There was an error while fetching data");
                };

                let out = [];
                for (let item of data.data) {
                  out.push({
                    "Date": moment(item.createdAt).toDate().toLocaleString(),
                    "Country": `${item.ClientInfo.DetectedCountry ?? "?"}`,
                    "City": `${item.ClientInfo.DetectedLocation ?? "?"}`,
                    "Type": item.ClientInfo.Device_device_type,
                    "IP": removeIPPadding(item.ClientInfo.IP),
                    "Duration": convertTimeToString(item.SessionDuration),
                    "Source": item.IntegrationData?.st_source,
                    "User-Agent": `"${String(item.ClientInfo.UserAgent ?? '').replace('"', "'")}"`
                  });
                };
                arrayToCSVString(out).then(data => {
                    if (data.status === "ok") {
                        let file = new Blob([data.data]);
                        var a = document.createElement("a"),
                            url = URL.createObjectURL(file);
                        a.href = url;
                        a.download = `ScaleTrack-Session-Export.csv`;
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(function () {
                            document.body.removeChild(a);
                            window.URL.revokeObjectURL(url);
                        }, 0);
                    } else {
                        args.errorMessage("Error while saving the CSV file");
                    };
                    args.close();
                }).catch(() => {
                    args.errorMessage("Error while saving the CSV file");
                }).finally(() => {
                    args.disabledAll(false);
                    args.spinner(false);
                });
            }}
        />)
    };

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
            getSums();
        }, 500);
    }, [props.search, props.filters, orders]);

    React.useEffect(() => {
        if (curOnScreen.isIntersecting && canPaginate) {
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch { };

            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => {
                continueData(ts);
            }, 500);
        };
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="modals__sessionsModal__wrap__content" style={{
        gridTemplateColumns: !selectedItem ? "1fr auto" : "calc(100% - 600px) 600px",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__sessionsModal__wrap__content__left" style={{position: "relative"}}>
            <div className="modals__sessionsModal__wrap__content__left__btns" style={{display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px"}}>
                <StyledButton style={{display: "flex", alignItems: "center", gap: "10px", padding: "0 20px"}} onClick={() => {
                    const filters = [];
                    if (props.search) {
                        filters.push({
                            or: [
                                { name: "LocationData.country", op: "like", value: props.search },
                                { name: "LocationData.city", op: "like", value: props.search },
                                { name: "NetworkData.IP", op: "like", value: props.search },
                                { name: "NetworkData.Referrer", op: "like", value: props.search },
                                { name: "DeviceData.UserAgent", op: "like", value: props.search },
                                { name: "DeviceData.Device.os.name", op: "like", value: props.search },
                                { name: "DeviceData.Device.client.name", op: "like", value: props.search },
                                { name: "DeviceData.Device.device.type", op: "like", value: props.search }
                            ]
                        })
                    };
                    if (Array.isArray(props.filters)) filters.push(...props.filters);
                    filters.push({name: "CampaignID", op: "in", value: getCampaignIDs()});
                    animateBox(<SessionsModal_BotDetection filters={filters} />);
                }}>
                    <img src="/images/icon_botDetect.svg" />
                    <span>Bot detection AI</span>
                </StyledButton>
                {userInfoSelector?.Flags?.isAdmin && <StyledButton onClick={performExport}>Export</StyledButton>}
            </div>
            <div className="modals__sessionsModal__wrap__content__left__tableWrap">
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={
                        props.integration === -2 ?
                            ["No.", "Date", "ClickID", "Country / City", "Type", "IP", "Duration", "Source", "User-agent", "Creative", "Device", ""] :
                            ["No.", "Date", "ClickID", "Country / City", "Type", "IP", "Duration", "Source", "User-agent", "Device", ""]
                    }
                    customColumns={
                        props.integration === -2 ?
                            ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "200px", "auto", "auto", "18px"] :
                            ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "200px", "auto", "18px"]
                    }
                    spinnerColor="#fff"
                    showSpinner={globalSpinner}
                    orderCB={o => orderDefer(() => setOrders(o), 500)}
                    stickyFooter={-3}
                    stickyFooterClass="modals__sessionsModal__wrap__content__left"
                    stickyHeader={105}
                    stickyHeaderClass="modals__sessionsModal__wrap__content__left"
                    data={(() => {
                        let out = [];

                        if (!data) return [[{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]];
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                

                                out.push({
                                    events: {
                                        onClick: () => {
                                            setSelectedItem(item);
                                        }
                                    },
                                    columns: [
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(<>
                                            {data.data.indexOf(item) + 1}
                                            {item.isTest ? <span style={{color: "#ffff0061", marginLeft: "10px"}}>[TEST]&nbsp;</span> : null}
                                            {item.isAPI ? <span style={{color: "rgb(108, 93, 211", marginLeft: "10px"}}>[API]&nbsp;</span> : null}
                                        </>) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(moment(item.createdAt).toDate().toLocaleString()) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.ID.substring(0, 10) + "..."), onClick: e => {e?.stopPropagation?.(); navigator.clipboard.writeText(item.ID)} },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(`${item.ClientInfo.DetectedCountry ?? "?"} / ${item.ClientInfo.DetectedLocation ?? "?"}`) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.ClientInfo.Device_device_type) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(removeIPPadding(item.ClientInfo.IP)) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(convertTimeToString(item.SessionDuration)) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(item.IntegrationData?.st_source) },
                                        { keyID: generateKey(item), type: "text", text: wrapTableItemWithDots(item.ClientInfo.UserAgent) },
                                        (props.integration === -2 ? {keyID: generateKey(item), type: "text", text: wrapTableItem(item.IntegrationData?.["stcrt"])} : null),
                                        { keyID: generateKey(item), type: "text", text: wrapTableItem(`${item.ClientInfo.Device_device_brand || "?"} / ${item.ClientInfo.Device_device_model || "?"}`) },
                                        { keyID: generateKey(item), type: "custom", data: <img className="modals__sessionsModal__wrap__content__left__img" src="/images/campaigns/campaign_openInNewTab.svg" /> }
                                    ].filter(f => f),
                                    style: {
                                        backgroundColor: item === selectedItem ? "rgb(75, 70, 113)" : null
                                    }
                                })
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (spinner) out.push([{ keyID: "pagination-spinner", type: "spinner", color: "white" }]);

                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show for now." }]);

                        if (dataSum?.status === "ok") {
                            if (data?.status === "ok") {
                                if (data?.data?.length > 0) {
                                    out.push({
                                        isFooter: true,
                                        columns: [
                                            { keyID: "total-col", type: "text", text: "Total" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: wrapTableItem(convertTimeToString(dataSum?.data?.SessionDuration ?? 0)) },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" },
                                            { keyID: "total-col", type: "text", text: "" }
                                        ]
                                    })
                                };
                            };
                        };

                        return out;
                    })()}
                />
            </div>

            {canPaginate && <div ref={curOnScreen.measureRef} className="modals__sessionsModal__wrap__content__left__paginate"></div>}
        </div>
        <SelectedSession item={selectedItem} onClose={() => setSelectedItem()} campaign={{ integration: props.integration, item: props.item }} theme={themeSelector} />
    </div>;
};

const SelectedSession = props => {
    const [activeTab, setActiveTab] = React.useState();
    const [journeyData, setJourneyData] = React.useState();

    const tabsRef = React.useRef();

    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    const onClose = () => {
        setActiveTab();
        setJourneyData();
        props.onClose();
    };

    const getDataImage = key => {
        switch (key) {
            default: return "/images/campaign_orders/order_contact_info.svg"
        };
    };

    const getIntegrationImage = (key, isAPI) => {
        if (isAPI) return "/images/integrations/integration_header_api.svg";
        switch (key) {
            case null: return "/images/logo.svg";
            case 0: return "/images/integrations/integration_header_facebook.svg";
            case 1: return "/images/integrations/integration_header_mgid.svg";
            case 4: return "/images/integrations/integration_midas.svg";
            case 5: return "/images/integrations/integration_adnow.svg";
            case -2: return "/images/integrations/integration_smsMarketing.svg";
            case -3: return "/images/integrations/integration_socialCampaigns.svg";
            default: return "/images/image-missing.png";
        };
    };

    const translateDataKey = key => {
        if (key === "IP") return key;

        let tmp = key.split("");
        let finalName = tmp.shift();

        for (let item of tmp) {
            if (uppercaseLetters.includes(item)) {
                finalName += " ";
            };
            finalName += item;
        };

        return finalName;
    };

    const journeyObjectToDivs = (obj, TrackID) => {
        let out = [];

        for (let key of Object.keys(obj)) {
            if (!key) continue;
            if (key === "session_record") {
                out.push(<div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                    <p>{translateDataKey(key)}</p>
                    <span>{obj[key]} <StyledButton style={{marginLeft: "10px", height: "30px", backgroundColor: "transparent", border: "1px solid #6c5dd3", padding: "0 20px"}} onClick={() => openRecord(TrackID, obj[key])}>View recording</StyledButton></span>
                </div>)
                continue;
            };
            if (typeof (obj[key]) === "object" && !Array.isArray(obj[key])) {
                let tmp = [];
                for (let key2 of Object.keys(obj[key])) {
                    tmp.push(<span>{key2}: {obj[key][key2]}</span>)
                };
                out.push(<div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                    <p>{translateDataKey(key)}</p>
                    {tmp}
                </div>);
            } else {
                out.push(<div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                    <p>{translateDataKey(key)}</p>
                    <span>{obj[key]}</span>
                </div>);
            };
        };

        return out;
    };

    const changeTab = (idx, skipFirst = false) => {
        if (activeTab === idx) return;

        if (!tabsRef.current) return setActiveTab(idx);

        let allItems = [];

        for (let item of tabsRef.current.querySelectorAll("div")) {
            allItems.push(new Promise(r => {
                item.animate([
                    { left: getComputedStyle(item).left },
                    { left: "150%" }
                ], {
                    duration: skipFirst ? 0 : 300,
                    iterations: 1,
                    fill: "both",
                    easing: "ease"
                }).onfinish = () => r();
            }))
        };

        Promise.allSettled(allItems).then(() => {
            setActiveTab(idx)
            if (!tabsRef.current) return;

            let curTab = tabsRef.current.querySelector(`[data-tab="${idx}"]`);
            if (!curTab) return;
            curTab.animate([
                { left: getComputedStyle(curTab).left },
                { left: "0" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        });
    };

    const sortClientKeys = keys => {
        let sortKeys = {
            
        };
        let out = [...keys];

        out = out.sort((a, b) => {
            let aKey = sortKeys[a] ?? 0;
            let bKey = sortKeys[b] ?? 0;
            if (aKey > bKey) return 1;
            return -1;
        });

        return out;
    };

    const displayClientInfo = info => {
        switch (typeof (info)) {
            case "boolean":
                return <span style={{ color: info ? "#71ec71" : "#ec7171" }}>{info ? "Yes" : "No"}</span>
            case "object":
                if (Array.isArray(info)) return `${info.length} item${info.length > 1 ? "s" : ""}`;
                return `${Object.keys(info).length} item${Object.keys(info).length > 1 ? "s" : ""}`;
            default: return String(info);
        };
    };

    const checkIfCurrentJourney = journey => {
        if (props.campaign.integration === journey.IntegrationType) {
            if (props.campaign.item.ID === journey.CampaignID) {
                return true;
            };
        };
        return false;
    };

    const getSessionDuration = sd => {
        let hours = 0;
        let minutes = 0;
        let seconds = sd;

        while (seconds > 60) {
            minutes++;
            seconds -= 60;
        };
        while (minutes > 60) {
            hours++;
            minutes -= 60;
        };
        
        let tmp = `${seconds}s`;
        if (minutes > 0 || hours > 0) {
            tmp = `${minutes}m : ${tmp}`;
        };
        if (hours > 0) {
            tmp = `${hours}h : ${tmp}`;
        };

        return tmp;
    };

    const returnEvents = (events) => {
        let out = [];

        for (let item of events) {
            out.push(<span>{item.EventName}</span>);
            out.push(<div>
                {journeyObjectToDivs(item.EventValues)}
            </div>)
        };

        return out;
    };

    const openRecord = (ID, val) => {
        if (!ID || !val) return;
        let tmp = String(val).split(":");
        if (tmp.length < 2) return;

        let name = tmp.shift();
        let value = tmp.join(":");

        let startDate = moment().add(-20, "years").toDate().getTime();
        let endDate = moment().toDate().getTime();

        switch (name) {
            case "clarity":
                console.log(ID, name, value);
                window.open(`https://clarity.microsoft.com/projects/view/${value}/impressions?CustomUserId=is%3B${ID}&date=Custom&end=${endDate}&start=${startDate}`, "_blank");
            default: return;
        };
    };

    React.useEffect(() => {
        setJourneyData();
        if (!props.item) return;
        if (!props.item?.ID) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getClientJourney`,
            data: {
                TrackID: props.item.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setJourneyData(res.data);
        }).catch(() => {
            setJourneyData(backendModule.genericError);
        });
    }, [props.item]);

    React.useEffect(() => {
        if (!tabsRef.current) return;
        if (activeTab !== undefined) return;

        changeTab(0, true);
    });

    return <div className={`modals__sessionsModal__wrap__content__right ${props.item ? "modals__sessionsModal__wrap__content__right--active" : ""}`} style={{
        minHeight: 0,
        height: "100%"
    }}>
        <div className="modals__sessionsModal__wrap__content__right__wrap">

            {props.item && <>
                <div className="modals__sessionsModal__wrap__content__right__wrap__head">
                    <div className="modals__sessionsModal__wrap__content__right__wrap__head__top">
                        {props?.item?.ClientInfo?.IP ?? "?"}
                    </div>
                    <div className="modals__sessionsModal__wrap__content__right__wrap__head__bottom">
                        Click ID: {props.item.ID ?? "-"}
                    </div>
                    <div className="modals__sessionsModal__wrap__content__right__wrap__head__bottom">
                        Location: {props.item.ClientInfo.DetectedCountry ?? "?"} / {props.item.ClientInfo.DetectedLocation ?? "?"}
                    </div>

                    {props.item?.IntegrationData?.session_record && <div className="modals__sessionsModal__wrap__content__right__wrap__head__bottom">
                        <StyledButton style={{marginTop: "10px", height: "30px", backgroundColor: "transparent", border: "1px solid #6c5dd3"}} onClick={() => openRecord(props.item?.ID, props.item?.IntegrationData?.session_record)}>View recording</StyledButton>
                    </div>}

                    <div className="modals__sessionsModal__wrap__content__right__wrap__head__close" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
                </div>

                <div className="modals__sessionsModal__wrap__content__right__wrap__tabs">
                    <div className={`modals__sessionsModal__wrap__content__right__wrap__tabs__tab ${activeTab === 0 ? "modals__sessionsModal__wrap__content__right__wrap__tabs__tab--active" : ""}`} onClick={() => changeTab(0)}>
                        <JourneyTab_Img />
                        <span>Journey</span>
                    </div>

                    <div className={`modals__sessionsModal__wrap__content__right__wrap__tabs__tab ${activeTab === 1 ? "modals__sessionsModal__wrap__content__right__wrap__tabs__tab--active" : ""}`} onClick={() => changeTab(1)}>
                        <InfoTab_Img />
                        <span>General info</span>
                    </div>
                </div>

                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap" ref={tabsRef}>
                    <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey" data-tab={0}>
                            {journeyData ? <>
                                {journeyData.status === "ok" ? <>
                                    {journeyData.data.map((journey, journeyIdx) => {
                                        return <div className={`modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item`}>
                                            <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__left">
                                                <img src={getIntegrationImage(journey.IntegrationType, journey.isAPI)} />
                                                {journeyIdx !== journeyData.data.length - 1 && <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__left__line"></div>}
                                            </div>
                                            <div className={`modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right ${(journey.hasConverted && !journey.isPreLander) ? "modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right--converted" : ""}`}>
                                                {journey.isAPI && <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p style={{color: "rgb(108, 93, 211)"}}>API</p>
                                                </div>}
                                                {checkIfCurrentJourney(journey) && <div className={`modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock--active`}>
                                                    <p>Current campaign</p>
                                                </div>}

                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Click ID</p>
                                                    <span>{journey.ID}</span>
                                                </div>
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Date created</p>
                                                    <span>{(new Date(journey.createdAt).toLocaleString())}</span>
                                                </div>
                                                {journey.convertedAt && <div className="modals__ordersModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Date converted</p>
                                                    <span>{(new Date(journey.convertedAt).toLocaleString())}</span>
                                                </div>}
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Session duration</p>
                                                    <span>{getSessionDuration(journey.SessionDuration)}</span>
                                                </div>
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Type</p>
                                                    <span>{journey.isPreLander ? <span style={{color: "rgb(238, 214, 84)"}}>Pre-Landing page</span> : <span style={{color: "#69f978"}}>Landing (offer) page</span>}</span>
                                                </div>
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Has interacted</p>
                                                    <span>{journey.hasInteracted ? "Yes" : "No"}</span>
                                                </div>
                                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__textBlock">
                                                    <p>Has Converted</p>
                                                    <span>{journey.hasConverted ? "Yes" : "No"}</span>
                                                </div>

                                                {journeyObjectToDivs(journey.IntegrationData, journey.ID)}

                                                {journey._image && <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__image" onClick={e => {
                                                    animateBox(e, <PreviewImageModal image={journey._image} />);
                                                }}>
                                                    <img src={journey._image} />    
                                                </div>}

                                                {Object.keys(journey.IntegrationParams ?? {}).length > 0 && <>
                                                    <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__line"></div>
                                                    {journeyObjectToDivs(journey.IntegrationParams, journey.ID)}
                                                </>}

                                                {journey.Events.length > 0 && <>
                                                    <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__line"></div>
                                                    <h4>Events</h4>
                                                    <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__item__right__events">
                                                        {returnEvents(journey.Events)}
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    })}
                                </> : <p className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__journey__infoP">There was an error while fetching the journey!</p>}
                            </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" align="center" />}
                        </div>

                    <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__contacts" data-tab={1}>
                        {sortClientKeys(Object.keys(props.item.ClientInfo)).map(key => {
                            return <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__contacts__contact">
                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__contacts__contact__left">
                                    <img src={getDataImage(key)} />
                                </div>
                                <div className="modals__sessionsModal__wrap__content__right__wrap__tabWrap__item__contacts__contact__right">
                                    <span>{translateDataKey(key)}</span>
                                    <span>{displayClientInfo(props.item.ClientInfo[key])}</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </>}

        </div>
    </div>
};

const SessionsModal_BotDetection = props => {
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(true);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const wrapRef = React.useRef();

    const getBotData = () => {
        let filters = [];
        if (props.filters) filters.push(...props.filters);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/botDetection/botCheck`,
            data: {
                filters,
                returnIDs: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            return setData(res.data);
        }).catch(() => {
            return setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const calculatePercent = (current, total) => {
        let tmp = `(0.00 %)`;
        current = Number(current);
        total = Number(total);

        if (isNaN(current) || isNaN(total)) return tmp;
        if (!current || !total) return tmp;

        return `(${Number((current / total) * 100).toFixed(2)} %)`;
    };

    const sortObjectTop5 = obj => {
        let keys = Object.keys(obj);
        keys = keys.sort((a, b) => {
            if (obj[a] > obj[b]) return -1;
            return 1;
        });

        let out = [];
        for (let key of keys.splice(0, 5)) {
            out.push(<p>{key} <span>(seen {obj[key]} times)</span></p>)
        };

        return out;
    };

    const exportData = (data, name) => {
        if (data.length === 0) return;

        let headers = Object.keys(data[0]);

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet(name);

        const convertTime = (time) => {
            let totalSeconds = Math.floor(time);
            let s = totalSeconds % 60;
            let m = Math.floor(totalSeconds / 60);

            let out = [];
            if (m) {
                out.push(`${m}m`);
                out.push(`${s}s`);
            } else if (s) {
                out.push(`${s}s`);
            };

            return out.join(" ");
        };

        sheet.addTable({
            name: name,
            "ref": "A1",
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: headers.map(hi => { return { name: hi } }),
            rows: data.map(c => {
                let out = [];
                for (let item of headers) {
                    if (item === "firstInteraction" || item === "sessionDuration") {
                        if (item === "firstInteraction") c[item] = c[item] ? c[item] / 1000 : null
                        out.push(convertTime(c[item]));
                    } else {
                        out.push(c[item]);
                    };
                };
                return out;
            })
        });
        sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        let fileName = `Export-bot-detection-ai-${name}`;
        fileName += ".xlsx";

        workbook.xlsx.writeBuffer().then(data => {
            let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);

        }).catch(() => null);
    };

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getBotData();
    }, []);

    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Bot detection AI</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                {spinner ? <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} /> : <>
                    {data ? data.status === "ok" ? <div className="route__admin__botDetection__data">
                        <p>Total sessions: <span>{data.data.stats.totalRequests.length}</span></p>
                        <br />

                        {/* <p style={{display: "flex", alignItems: "center"}}>Sessions without interaction: <span>{data.data.stats.hasNoInteraction.length} {calculatePercent(data.data.stats.hasNoInteraction.length, data.data.stats.totalRequests.length)}</span> <img title="How many sessions haven't had any interactions. For an interaction to count, user must click on the page, scroll or hover over an element of the page." src="/images/question_mark.svg" /></p> */}
                        <p>Zero-duration sessions: <span>{data.data.stats.sessionDurationLessThan_0s.length} {calculatePercent(data.data.stats.sessionDurationLessThan_0s.length, data.data.stats.totalRequests.length)}</span></p>
                        <p>Sessions with duration &lt; 2s: <span>{data.data.stats.sessionDurationLessThan_2s.length} {calculatePercent(data.data.stats.sessionDurationLessThan_2s.length, data.data.stats.totalRequests.length)}</span></p>
                        <p>Total suspicious sessions: <span>{data.data.suspicious.length} {calculatePercent(data.data.suspicious.length, data.data.stats.totalRequests.length)}</span>  {data.data.suspicious.length > 0 && <StyledButton style={{height: "100%"}} onClick={() => exportData(data.data.suspicious, "Bot-suspicious")}>Export</StyledButton>}</p>

                        {Object.keys(data.data.repeatings.repeatingIPSuspicious).length > 0 && <>
                            <br />
                            <h4 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Top 5 suspicious IPs</h4>
                            {sortObjectTop5(data.data.repeatings.repeatingIPSuspicious)}
                        </>}

                        {Object.keys(data.data.repeatings.repeatingUserAgentSuspicious).length > 0 && <>
                            <br />
                            <h4 style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Top 5 suspicious User Agents</h4>
                            {sortObjectTop5(data.data.repeatings.repeatingUserAgentSuspicious)}
                        </>}
                    </div> : <p>There was an error while fetching data</p> : null}
                </>}
            </div>
        </div>
    </div>
};

export default SessionModal;