import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";
import axios from "axios";
import moment from "moment";

import * as backendModule from "../../modules/backendModule";
import * as siteFunctionsActions from "../../actions/siteFunctionsActions";
import { getParamsFromURLObject } from "../../modules/urlModule";
import { animateBox } from "../../modules/componentAnimation";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import FilterBySearch from "../../components/filters/FilterBySearch";
import FilterByDate from "../../components/filters/FilterByDate";

const SocialMediaManager = () => {
    const [data, setData] = React.useState();
    const [stats, setStats] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [search, setSearch] = React.useState();
    const [dateFilter, setDateFilter] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curDispatch = useDispatch();
    const curDefer = useDefer();
    const searchDefer = useDefer();
    const curOnScreen = useOnScreen();

    const completeAd = (ad) => {
        if (!data) return;
        if (data?.status !== "ok") return;
        if (!ad) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/facebook_markAdAsCompleted`,
            data: {
                AdID: ad
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(d => {
                    return {
                        ...d,
                        data: d.data.map(dd => {
                            let tmp = {...dd};
                            if (tmp.AdID === ad) {
                                tmp.Completed = true;
                                tmp.updatedAt = Date.now();
                            };
                            return tmp;
                        })
                    };
                });
            };
        }).catch(() => null);
    };

    const getStats = (ts) => {
        if (timestampRef.current !== ts) return;

        let filters = [];
        if (search) {
            filters.push({or: [
                {name: "CampaignName", op: "like", value: search},
                {name: "AdName", op: "like", value: search},
                {name: "AdID", op: "like", value: search}
            ]});
        };
        if (dateFilter) {
            filters.push({name: "AdCreatedDate", op: "dgeq", value: dateFilter.start.toDate().getTime()});
            filters.push({name: "AdCreatedDate", op: "dleq", value: dateFilter.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsSocialMediaStats`,
            data: {
                filters
            }
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setStats(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setStats(backendModule.genericError);
        });
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        if (canPaginate) setCanPaginate(false);

        let filters = [];
        if (search) {
            filters.push({or: [
                {name: "CampaignName", op: "like", value: search},
                {name: "AdName", op: "like", value: search},
                {name: "AdID", op: "like", value: search}
            ]});
        };
        if (dateFilter) {
            filters.push({name: "AdCreatedDate", op: "dgeq", value: dateFilter.start.toDate().getTime()});
            filters.push({name: "AdCreatedDate", op: "dleq", value: dateFilter.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsSocialMedia`,
            data: {
                limit: 20,
                offset: 0,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;

            setData(backendModule.axiosConfig);
        });
    };

    const continueData = () => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!canPaginate) return;
        setCanPaginate(false);

        let filters = [];
        if (search) {
            filters.push({or: [
                {name: "CampaignName", op: "like", value: search},
                {name: "AdName", op: "like", value: search},
                {name: "AdID", op: "like", value: search}
            ]});
        };
        if (dateFilter) {
            filters.push({name: "AdCreatedDate", op: "dgeq", value: dateFilter.start.toDate().getTime()});
            filters.push({name: "AdCreatedDate", op: "dleq", value: dateFilter.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsSocialMedia`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const openURL = url => {
        window.open(url, "_blank");
    };

    React.useEffect(() => {
        if (dateFilter === undefined) return;

        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => {
                getData(ts);
                getStats(ts);
            }, 1000);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [search, dateFilter]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        setCanPaginate(false);
        curDefer(() => continueData(), 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__socialMediaManager">
        <div className="route__socialMediaManager__filters">
            <FilterBySearch onChange={e => searchDefer(() => setSearch(e), 1000)} />
            <FilterByDate defaultValue="today" onChange={e => setDateFilter(e)} />
        </div>
        <br />

        <div className="route__socialMediaManager__kpi">

            <div className="route__socialMediaManager__kpi__item">
                <div className="route__socialMediaManager__kpi__item__top">New</div>
                <div className="route__socialMediaManager__kpi__item__bottom">
                    {stats ? <>
                        {stats.status === "ok" ? stats.data.new : "?"}
                    </> : <Spinner style={{width: "28px", height: "28px"}} color="white" />}
                </div>
            </div>

            <div className="route__socialMediaManager__kpi__item">
                <div className="route__socialMediaManager__kpi__item__top">Done</div>
                <div className="route__socialMediaManager__kpi__item__bottom">
                    {stats ? <>
                        {stats.status === "ok" ? stats.data.done : "?"}
                    </> : <Spinner style={{width: "28px", height: "28px"}} color="white" />}
                </div>
            </div>

        </div>
        <br />

        <FilteredCustomTable
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["Campaign", "Ad", "Ad ID", "Ad created", "Completed", "", "", ""]}
            customColumns={["max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content"]}
            style={{columnGap: "30px"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]]
                if (data?.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data!"}]];

                let out = [];
                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.CampaignName},
                        {keyID: item.ID, type: "text", text: item.AdName},
                        {keyID: item.ID, type: "text", text: item.AdID},
                        {keyID: item.ID, type: "text", text: moment(item.AdCreatedDate).toDate().toLocaleString()},
                        {keyID: item.ID, type: "text", text: item.Completed ? <span style={{color: themeSelector === "dark" ? "rgb(102, 238, 102)" : "green"}}>{moment(item.completedAt).toDate().toLocaleString()}</span> : <span style={{color: themeSelector === "dark" ? "rgb(238, 102, 102)" : "red"}}>No</span>},
                        {keyID: item.ID, type: "button", text: "Open facebook ad", onClick: () => openURL(item.URL), style: {width: "70px"}},
                        (item.CampaignURL ? {keyID: item.ID, type: "button", text: "Open website", onClick: () => openURL(item.CampaignURL), style: {width: "70px"}} : null),
                        (item.Completed ? null : {keyID: item.ID, type: "button", text: "Complete", onClick: () => completeAd(item.AdID), style: {width: "70px"}}),
                        ((item.Completed || !item.URL) ? null : {keyID: item.ID, type: "button", text: "Link doesnt work", onClick: () => animateBox(<FixLink url={item.URL} />)})
                    ].filter(t => t))
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                return out;
            })()}
        />

        {canPaginate && <div style={{opacity: 0}} ref={curOnScreen.measureRef} />}
    </div>
};

const FixLink = props => {
    const [infoP, setInfoP] = React.useState("");

    const urlRef = React.useRef();

    const checkAndOpen = () => {
        setInfoP("");

        let curUrl = urlRef.current.value;
        if (!curUrl) return setInfoP("Link can't be empty");

        try {
            new URL(curUrl);
        } catch {
            return setInfoP("Link is invalid!");
        };

        let params = getParamsFromURLObject(curUrl);
        if (!params) return setInfoP("Link is invalid!");
        if (!params?.id) return setInfoP("Link is invalid (missing 'id' parameter)");
        if (!params?.story_fbid) return setInfoP("Link is invalid (missing 'story_fbid' parameter)");

        window.open(`https://facebook.com/${params.id}/posts/${params.story_fbid}`, "_blank");
        props.onClose();
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Fix link</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="genericModal__wrap__input">
                <p>Insert the link of the facebook post that doesnt work</p>
                <input ref={urlRef} type="text" placeholder="Facebook post link" />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn" onClick={checkAndOpen}>Fix and open</div>
            </div>

            {infoP && <p className="genericModal__wrap__infoP" style={{opacity: 1}}>{infoP}</p>}
        </div>
    </div>
};

export default SocialMediaManager;