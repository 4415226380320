import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../../../../modules/backendModule";
import { updateTrackingProfilesTimestamp } from "../../../../../actions/siteFunctionsActions";

import Spinner from "../../../../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../../../../components/customComponents/Table";
import AdvancedDropdown from "../../../../../components/customComponents/AdvancedDropdown";

const AddIntegration_Tiktok = (props) => {
    const [curState, setCurState] = React.useState({state: 1, data: null});
    // 1 - check existing tokens
    // 2 - processing
    // 3 - select account
    // 4 - check selected account

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();
    const tiktokWindowRef = React.useRef();

    const onClose = () => {
        try {
            tiktokWindowRef?.current?.close();
        } catch {};

        if (props.onChange) props.onChange();
        if (!mainRef?.current) return props.onClose();

        mainRef.current.animate([
            {right: getComputedStyle(mainRef.current).right},
            {right: "-100%"}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef?.current) return;

        mainRef.current.animate([
            {right: getComputedStyle(mainRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [mainRef.current]);

    

    if (props.regenToken) return <div className="route__user__integrations__addTiktok" onClick={onClose}>
        <div className="route__user__integrations__addTiktok__wrap" ref={mainRef} onClick={e => e.stopPropagation()}>
            <div className="route__user__integrations__addTiktok__wrap__head">
                <p className="route__user__integrations__addTiktok__wrap__head__text">Tiktok ads</p>
                <div className="route__user__integrations__addTiktok__wrap__head__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="route__user__integrations__addTiktok__wrap__data">
                {curState ? <>
                    <div className="route__user__integrations__addTiktok__wrap__data__steps">
                        <div className={`route__user__integrations__addTiktok__wrap__data__steps__step ${curState.state === 1 ? "route__user__integrations__addTiktok__wrap__data__steps__step--active" : ""} ${curState.state > 1 ? "route__user__integrations__addTiktok__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__num">1</p>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__text">Get token</p>
                        </div>

                        <div className={`route__user__integrations__addTiktok__wrap__data__steps__step ${curState.state === 2 ? "route__user__integrations__addTiktok__wrap__data__steps__step--active" : ""} ${curState.state > 2 ? "route__user__integrations__addTiktok__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__num">2</p>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__text">Update</p>
                        </div>

                        <div className={`route__user__integrations__addTiktok__wrap__data__steps__step ${curState.state === 3 ? "route__user__integrations__addTiktok__wrap__data__steps__step--active" : ""} ${curState.state > 3 ? "route__user__integrations__addTiktok__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__num">2</p>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__text">Finish</p>
                        </div>
                    </div>
                    
                    {curState.state === 1 && <AddIntegration_Tiktok_1_token ID={props.ID} setCurState={setCurState} tiktokWindowRef={tiktokWindowRef} />}
                    {curState.state === 2 && <AddIntegration_Tiktok_2_token ID={props.ID} setCurState={setCurState} token={curState.data} />}
                    {curState.state === 3 && <AddIntegration_Tiktok_4 ID={props.ID} setCurState={setCurState} onClose={onClose} />}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />}
            </div>
        </div>
    </div>

    return <div className="route__user__integrations__addTiktok" onClick={onClose}>
        <div className="route__user__integrations__addTiktok__wrap" ref={mainRef} onClick={e => e.stopPropagation()}>
            <div className="route__user__integrations__addTiktok__wrap__head">
                <p className="route__user__integrations__addTiktok__wrap__head__text">Tiktok ads</p>
                <div className="route__user__integrations__addTiktok__wrap__head__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="route__user__integrations__addTiktok__wrap__data">
                {curState ? <>
                    <div className="route__user__integrations__addTiktok__wrap__data__steps">
                        <div className={`route__user__integrations__addTiktok__wrap__data__steps__step ${curState.state === 1 ? "route__user__integrations__addTiktok__wrap__data__steps__step--active" : ""} ${curState.state > 1 ? "route__user__integrations__addTiktok__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__num">1</p>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__text">Connect</p>
                        </div>

                        <div className={`route__user__integrations__addTiktok__wrap__data__steps__step ${curState.state === 2 ? "route__user__integrations__addTiktok__wrap__data__steps__step--active" : ""} ${curState.state > 2 ? "route__user__integrations__addTiktok__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__num">2</p>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__text">Processing</p>
                        </div>

                        <div className={`route__user__integrations__addTiktok__wrap__data__steps__step ${curState.state === 3 ? "route__user__integrations__addTiktok__wrap__data__steps__step--active" : ""} ${curState.state > 3 ? "route__user__integrations__addTiktok__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__num">3</p>
                            <p className="route__user__integrations__addTiktok__wrap__data__steps__step__text">Account</p>
                        </div>
                    </div>
                    
                    {curState.state === 1 && <AddIntegration_Tiktok_1 ID={props.ID} setCurState={setCurState} tiktokWindowRef={tiktokWindowRef} />}
                    {curState.state === 2 && <AddIntegration_Tiktok_2 ID={props.ID} token={curState.data} setCurState={setCurState} />}
                    {curState.state === 3 && <AddIntegration_Tiktok_3 ID={curState?.data ?? props.ID} setCurState={setCurState} onClose={onClose} />}
                    {curState.state === 4 && <AddIntegration_Tiktok_4 ID={props.ID} setCurState={setCurState} onClose={onClose} />}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />}
            </div>
        </div>
    </div>
};

const AddIntegration_Tiktok_1 = props => {
    const [checkData, setCheckData] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();
    const tiktokResponseHandler = React.useRef(() => null);

    const changeToNextState = (data) => {
        if (!mainRef.current) return props.setCurState({state: 2, data});

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: "200%"}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"}).onfinish = () => {
            props.setCurState({state: 2, data});
        };
    };

    const getURL = (e) => {
        e.preventDefault();
        if (props.spinner) return;

        setInfoP("");
        setSpinner(true);

        let a = window.open("", "", `popup,width=600,height=750,left=${window.outerWidth-500}`);
        props.tiktokWindowRef.current = a;

        let onPopupClosed = () => {
            clearInterval(curInterval);
            curInterval = null;

            a.close();
            props.tiktokWindowRef.current = null;

            setInfoP("Window was closed prematurely");
            setSpinner(false);
        };

        tiktokResponseHandler.current = (type, data) => {
            onPopupClosed = () => null;

            a.close();
            props.tiktokWindowRef.current = null;
            setSpinner(false);

            switch (type) {
                case "tiktok-integration-error":
                    setInfoP("There was an error while authorizing. Have you denied the request?");
                    break;
                case "tiktok-integration-success":
                    if (data) {
                        setInfoP("");
                        changeToNextState(data);
                    } else {
                        setInfoP("Authentication succeeded, but token was not returned by Tiktok.");
                    };
                    break;
                default:
                    break;
            };
        };
        
        let curInterval = setInterval(() => {
            if (!a) return onPopupClosed();
            if (a.closed) return onPopupClosed();
        }, 1000);

        a.document.head.innerHTML = `
            <style>
                * {margin: 0; padding: 0; box-sizing: border-box;}
                html, body {width: 100%; height: 100%;}
                #inline-container-integration {
                    width: 100vw;
                    height: 100vh;
                    background-color: ${themeSelector === "dark" ? "#232630" : "#fff"};
                
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .customComponents__spinner {
                    all: initial;
                    width: 64px;
                    height: 64px;
                    font-family: "Montserrat";
                }
                .customComponents__spinner__path {
                    stroke: #00A3FF;
                    stroke-linecap: round;
                    transform-origin: center;
                    animation: spinner__dash 1.5s ease-in-out infinite, spinner__rotate 2s linear infinite;
                    transition: stroke 0.3s ease;
                }
                
                @keyframes spinner__rotate {
                    100% {
                        transform: rotate(360deg);
                    }
                }
                
                @keyframes spinner__dash {
                    0% {
                        stroke-dasharray: 1, 150;
                        stroke-dashoffset: 0;
                    }
                
                    50% {
                        stroke-dasharray: 90, 150;
                        stroke-dashoffset: -35;
                    }
                
                    100% {
                        stroke-dasharray: 90, 150;
                        stroke-dashoffset: -124;
                    }
                }
            </style>
        `;

        let root = createRoot(a.window.document.body);
        root.render(<div id="inline-container-integration">
            <Spinner color={themeSelector === "dark" ? "white" : "black"} />
        </div>);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/auth/login`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setTimeout(() => {
                    if (a.closed) {
                        setSpinner(false);
                        return setInfoP("Window was closed prematurely.");
                    };
                    a.window.location = res.data.data;
                }, 500);
            } else {
                a.close();
                setInfoP("An error occured while logging in.");
                setSpinner(false);
            };
        }).catch(() => {
            a.close();
            setInfoP("Server timed out");
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        let messageHandler = (e) => {
            if (!e) return;
            if (!e?.data) return;

            if (typeof(e.data) !== "object" || Array.isArray(e.data)) return;

            if (!e.data.type) return;
            if (typeof(e.data.type) !== "string") return;
            if (e.data.data) {
                if (typeof(e.data.data) !== "string") return;
            };

            if (typeof(tiktokResponseHandler.current) === "function") {
                tiktokResponseHandler.current(e?.data?.type, e.data?.data);
            };
        };

        window.addEventListener("message", messageHandler);

        return () => window.removeEventListener("message", messageHandler);
    }, [tiktokResponseHandler.current]);

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        if (!props.ID) return setCheckData(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/checkIntegration`,
            data: {
                Integration: 6,
                AccountID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 3, data: null});
            } else {
                return setCheckData(true);
            };
        }).catch(() => {
            return setCheckData(true);
        });
    }, []);

    if (!checkData) return <div className="route__user__integrations__addTiktok__wrap__data__step1" ref={mainRef}>
        <p>Checking for existing integrations...</p>

        <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
    </div>

    return <div className="route__user__integrations__addTiktok__wrap__data__step1" ref={mainRef}>
        <div className="route__user__integrations__addTiktok__wrap__data__step1__text">
            Integrate your tiktok Ads account to view campaigns, ad sets, ads and demographics.
        </div>
        <div className="route__user__integrations__addTiktok__wrap__data__step1__btn" onClick={getURL}>
            {spinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "24px", height: "24px"}} /> : "Link account"}
        </div>

        <p className="route__user__integrations__addTiktok__wrap__infoP">{infoP}</p>
    </div>
};

const AddIntegration_Tiktok_1_token = props => {
    const [checkData, setCheckData] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();
    const tiktokResponseHandler = React.useRef(() => null);

    const changeToNextState = (data) => {
        if (!mainRef.current) return props.setCurState({state: 2, data});

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: "200%"}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"}).onfinish = () => {
            props.setCurState({state: 2, data});
        };
    };

    const getURL = (e) => {
        e.preventDefault();
        if (props.spinner) return;

        setInfoP("");
        setSpinner(true);

        let a = window.open("", "", `popup,width=600,height=750,left=${window.outerWidth-500}`);
        props.tiktokkWindowRef.current = a;

        let onPopupClosed = () => {
            clearInterval(curInterval);
            curInterval = null;

            a.close();
            props.tiktokWindowRef.current = null;

            setInfoP("Window was closed prematurely");
            setSpinner(false);
        };

        tiktokResponseHandler.current = (type, data) => {
            onPopupClosed = () => null;

            a.close();
            props.tiktokkWindowRef.current = null;
            setSpinner(false);

            switch (type) {
                case "tiktok-integration-error":
                    setInfoP("There was an error while authorizing. Have you denied the request?");
                    break;
                case "tiktok-integration-success":
                    if (data) {
                        setInfoP("");
                        changeToNextState(data);
                    } else {
                        setInfoP("Authentication succeeded, but token was not returned by Tiktok.");
                    };
                    break;
                default:
                    break;
            };
        };
        
        let curInterval = setInterval(() => {
            if (!a) return onPopupClosed();
            if (a.closed) return onPopupClosed();
        }, 1000);

        a.document.head.innerHTML = `
            <style>
                * {margin: 0; padding: 0; box-sizing: border-box;}
                html, body {width: 100%; height: 100%;}
                #inline-container-integration {
                    width: 100vw;
                    height: 100vh;
                    background-color: ${themeSelector === "dark" ? "#232630" : "#fff"};
                
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .customComponents__spinner {
                    all: initial;
                    width: 64px;
                    height: 64px;
                    font-family: "Montserrat";
                }
                .customComponents__spinner__path {
                    stroke: #00A3FF;
                    stroke-linecap: round;
                    transform-origin: center;
                    animation: spinner__dash 1.5s ease-in-out infinite, spinner__rotate 2s linear infinite;
                    transition: stroke 0.3s ease;
                }
                
                @keyframes spinner__rotate {
                    100% {
                        transform: rotate(360deg);
                    }
                }
                
                @keyframes spinner__dash {
                    0% {
                        stroke-dasharray: 1, 150;
                        stroke-dashoffset: 0;
                    }
                
                    50% {
                        stroke-dasharray: 90, 150;
                        stroke-dashoffset: -35;
                    }
                
                    100% {
                        stroke-dasharray: 90, 150;
                        stroke-dashoffset: -124;
                    }
                }
            </style>
        `;

        let root = createRoot(a.window.document.body);
        root.render(<div id="inline-container-integration">
            <Spinner color="white" />
        </div>);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/auth/login`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setTimeout(() => {
                    if (a.closed) {
                        setSpinner(false);
                        return setInfoP("Window was closed prematurely.");
                    };
                    a.window.location = res.data.data;
                }, 500);
            } else {
                a.close();
                setInfoP("An error occured while logging in.");
                setSpinner(false);
            };
        }).catch(() => {
            a.close();
            setInfoP("Server timed out");
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        let messageHandler = (e) => {
            if (!e) return;
            if (!e?.data) return;

            if (typeof(e.data) !== "object" || Array.isArray(e.data)) return;

            if (!e.data.type) return;
            if (typeof(e.data.type) !== "string") return;
            if (e.data.data) {
                if (typeof(e.data.data) !== "string") return;
            };

            if (typeof(tiktokResponseHandler.current) === "function") {
                tiktokResponseHandler.current(e?.data?.type, e.data?.data);
            };
        };

        window.addEventListener("message", messageHandler);

        return () => window.removeEventListener("message", messageHandler);
    }, [tiktokResponseHandler.current]);

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/checkIntegration`,
            data: {
                Integration: 6,
                AccountID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data?.MustReconnect) {
                    return setCheckData(true);
                };
            };
            props.setCurState({state: 3, data: null});
        }).catch(() => {
            return setCheckData(true);
        });
    }, []);

    if (!checkData) return <div className="route__user__integrations__addTiktok__wrap__data__step1" ref={mainRef}>
        <p>Checking for existing integrations...</p>

        <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
    </div>

    return <div className="route__user__integrations__addTiktok__wrap__data__step1" ref={mainRef}>
        <div className="route__user__integrations__addTiktok__wrap__data__step1__text route__user__integrations__addTiktok__wrap__data__step1__text--error">
            Your token has been invalidated, please reconnect your tiktok account.
        </div>
        <div className="route__user__integrations__addTiktok__wrap__data__step1__btn" onClick={getURL}>
            {spinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "24px", height: "24px"}} /> : "Link account"}
        </div>

        <p className="route__user__integrations__addTiktok__wrap__infoP">{infoP}</p>
    </div>
};

const AddIntegration_Tiktok_2 = props => {
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        if (!props.token) return setInfoP("Token is invalid, please try again later.");

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/addIntegration`,
            data: {
                Integration: 6,
                Key: props.token
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 3, data: res.data.data});
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    return setInfoP("An integration of this type already exists.");
                } else {
                    return setInfoP("An error occured while creating an integration");
                };
            };
        }).catch(() => {
            return setInfoP("Server timed out while creating an integration!");
        });
    }, []);

    return <div className="route__user__integrations__addTiktok__wrap__data__step2" ref={mainRef}>
        {!infoP ? <>
            <p className="route__user__integrations__addTiktok__wrap__data__step2__text">We are verifying your information and creating an integration. This shouldnt take long, please wait...</p>
            <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
        </> : <>
            <p className="route__user__integrations__addTiktok__wrap__infoP">{infoP}</p>
        </>}
    </div>
};

const AddIntegration_Tiktok_2_token = props => {
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        if (!props.token) return setInfoP("Token is invalid, please try again later.");

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/updateIntegration`,
            data: {
                Integration: 6,
                Key: props.token,
                AccountID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 3, data: null});
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    return setInfoP("An integration of this type already exists.");
                } else {
                    return setInfoP("An error occured while updating an integration");
                };
            };
        }).catch(() => {
            return setInfoP("Server timed out while updating an integration!");
        });
    }, []);

    return <div className="route__user__integrations__addTiktok__wrap__data__step2" ref={mainRef}>
        {!infoP ? <>
            <p className="route__user__integrations__addTiktok__wrap__data__step2__text">We are verifying your information and updating the integration. This shouldnt take long, please wait...</p>
            <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
        </> : <>
            <p className="route__user__integrations__addTiktok__wrap__infoP">{infoP}</p>
        </>}
    </div>
};

const AddIntegration_Tiktok_3 = props => {
    const [infoP, setInfoP] = React.useState("");
    const [data, setData] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [selectedAccounts, setSelectedAccounts] = React.useState([]);
    const [selectedBusinessAccounts, setSelectedBusinessAccounts] = React.useState([]);
    const [selectedBannedAccounts, setSelectedBannedAccounts] = React.useState([]);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);

    const mainRef = React.useRef();
    const curDispatch = useDispatch();

    const personalTableFunctionsRef = React.useRef();
    const businessTableFunctionsRef = React.useRef();
    const bannedTableFunctionsRef = React.useRef();

    const finishSetup = () => {
        if (spinner) return;
        if (selectedAccounts.length === 0 && selectedBusinessAccounts.length === 0) return;

        setInfoP("");
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/updateIntegrationData`,
            data: {
                SelectedProfiles: [...selectedAccounts, ...selectedBusinessAccounts, ...selectedBannedAccounts],
                AccountID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(updateTrackingProfilesTimestamp());
                props.setCurState({state: 4, data: null});
            } else {
                setInfoP("There was an error while saving account info.");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (!data.data) return;

        let personalOut = [];
        let businessOut = [];
        let bannedOut = [];


        for (let item of trackingProfilesSelector) {
            let newID = item.ID.split("-");
            newID.shift();
            newID = newID.join("-");

            let foundProfile = data.data.find(p => p.ID === newID);
            if (!foundProfile) continue;

            if (foundProfile.Type === "business") {
                businessOut.push(newID);
            } else if (foundProfile.Type === "banned") {
                bannedOut.push(newID);
            } else {
                personalOut.push(newID);
            };
        };

        if (personalOut.length > 0) {
            personalTableFunctionsRef.current?.select?.(personalOut);
        };
        if (businessOut.length > 0) {
            businessTableFunctionsRef.current?.select?.(businessOut);
        };
        if (bannedOut.length > 0) {
            bannedTableFunctionsRef.current?.select?.(bannedOut);
        };
    }, [data]);

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/getAdAccounts`,
            data: {
                AccountID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__integrations__addTiktok__wrap__data__step3" ref={mainRef}>
        {data ? <>
            {data.status === "ok" ? <>
                {data.data.length === 0 ? <>
                    <p>There aren't any Ad accounts accessible by your tiktok profile. Please try again later.</p>
                </> : <>
                    <p>
                        <span>Please select the AD accounts that will be used for tracking.</span>
                        <span>You can select a couple of them or all accounts.</span>
                    </p>

                    {data.data.filter(acc => acc.Type === "personal").length > 0 && <>
                        <p className="route__user__integrations__addTiktok__wrap__data__step3__accType">Personal accounts</p>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["Name", "Status"]}
                            customColumns={["1fr", "100px"]}
                            data={data.data.filter(acc => acc.Type === "personal").map(elem => {
                                return [
                                    {keyID: elem.ID, type: "custom", data: <p className="route__user__integrations__addTiktok__wrap__data__step3__tableUsername">
                                        <span>{elem.ID}</span>
                                        <span>{elem.Name}</span>
                                    </p>},
                                    {keyID: elem.ID, type: "custom", data: <p>[{elem.Status}] {elem.StatusCode}</p>}
                                ];
                            })}
                            checkboxCB={(acc) => setSelectedAccounts(acc)}
                            checkboxFunctions={fn => personalTableFunctionsRef.current = fn()}
                        />
                    </>}

                    {data.data.filter(acc => acc.Type === "business").length > 0 && <>
                        <p className="route__user__integrations__addTiktok__wrap__data__step3__accType">Business accounts</p>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["Name", "Status"]}
                            customColumns={["1fr", "100px"]}
                            data={data.data.filter(acc => acc.Type === "business").map(elem => {
                                return [
                                    {keyID: elem.ID, type: "custom", data: <p className="route__user__integrations__addTiktok__wrap__data__step3__tableUsername">
                                        <span>{elem.ID}</span>
                                        <span>{elem.Name}</span>
                                    </p>},
                                    {keyID: elem.ID, type: "custom", data: <p>[{elem.Status}] {elem.StatusCode}</p>}
                                ];
                            })}
                            checkboxCB={(acc) => setSelectedBusinessAccounts(acc)}
                            checkboxFunctions={fn => businessTableFunctionsRef.current = fn()}
                        />
                    </>}

                    {data.data.filter(acc => acc.Type === "banned").length > 0 && <>
                        <p className="route__user__integrations__addTiktok__wrap__data__step3__accType">Banned accounts</p>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["Name", "Status"]}
                            customColumns={["1fr", "100px"]}
                            data={data.data.filter(acc => acc.Type === "banned").map(elem => {
                                return [
                                    {keyID: elem.ID, type: "custom", data: <p className="route__user__integrations__addTiktok__wrap__data__step3__tableUsername">
                                        <span>{elem.ID}</span>
                                        <span>{elem.Name}</span>
                                    </p>},
                                    {keyID: elem.ID, type: "custom", data: <p>[{elem.Status}] {elem.StatusCode}</p>}
                                ];
                            })}
                            checkboxCB={(acc) => setSelectedBannedAccounts(acc)}
                            checkboxFunctions={fn => bannedTableFunctionsRef.current = fn()}
                        />
                    </>}

                    {(selectedAccounts.length > 0 || selectedBusinessAccounts.length > 0) && <div className="route__user__integrations__addTiktok__wrap__data__step3__btn" onClick={finishSetup}>
                        {spinner ? <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Finish"}
                    </div>}

                    {infoP && <p className="route__user__integrations__addTiktok__wrap__infoP">{infoP}</p>}
                </>}
            </> : <>
                <p>There was an error while fetching your ad accounts. Please try again later or try to disconnect and reconnect Your tiktok account.</p>
                <button className="route__user__integrations__addTiktok__wrap__data__step3__close" onClick={props.onClose}>Close</button>
            </>}
        </> : <>
            <p>Please wait while we get your ad accounts...</p>
            <Spinner align="center" color={themeSelector === "dark" ? "white" : "black"} />
        </>}
    </div>
};

const AddIntegration_Tiktok_4 = props => {
    const [utmParams, setUTMParams] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/tiktok/getUTMParams`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setUTMParams(res.data.data ?? "");
            } else {
                setUTMParams("");
            };
        }).catch(() => {
            setUTMParams("");
        });
    }, []);

    return <div className="route__user__integrations__addTiktok__wrap__data__step3" ref={mainRef}>
        {utmParams !== undefined ? <>
            <p style={{userSelect: "none"}}>Place the following code in Your URL parameters in Your "Ads"</p>
            <div className="route__user__integrations__addTiktok__wrap__data__step3__code">
                {utmParams}
            </div>

            <p>This is a generic code, for a better support close this window and click on the "i" icon under tiktok. Select your ad account there and you will get personalized ad url parameters.</p>

            <p className="route__user__integrations__addTiktok__wrap__data__step3__note">IMPORTANT: Changing the URL parameters in Your ads will put Your campaigns under review!!!</p>

            <p>Your account is all set up!</p>
            <p>That is all the info we need. Do note that it might take some time before Your campaigns and accounts start showing up in the dashboard (up to 1 hour).</p>

            <button className="route__user__integrations__addTiktok__wrap__data__step3__close" onClick={props.onClose}>Finish</button>
        </> : <>
            <p>Almost done...</p>
            <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
        </>}
    </div>
};

export default AddIntegration_Tiktok;