import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";

import Dropdown from "../../customComponents/Dropdown";
import Spinner from "../../customComponents/Spinner";

const CreateLeadModal = props => {
    const [offers, setOffers] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [infoP, setInfoP] = React.useState({hadError: false, text: "", inputs: []});
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const firstLastNameRef = React.useRef();
    const phoneNumberRef = React.useRef();

    const getOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/offers/getAllOffers`,
            data: {
                limit: null,
                filters: [
                    {name: "ScaleleadOfferID", op: "neq", value: null},
                    {name: "ScaleleadOfferID", op: "neq", value: ""}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => setOffers(res.data)).catch(() => setOffers(backendModule.genericError));
    };

    const createLead = () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        const data = {
            OfferID: selectedOffer,
            FirstLastName: firstLastNameRef.current.value,
            PhoneNumber: phoneNumberRef.current.value
        };

        if (!data.OfferID) return setInfoP(ip => {return {...ip, hadError: true, text: "Offer must be selected", inputs: ["offer"]}});
        if (!data.FirstLastName) return setInfoP(ip => {return {...ip, hadError: true, text: "First and last name can't be empty", inputs: ["firstlastname"]}});
        if (!data.PhoneNumber) return setInfoP(ip => {return {...ip, hadError: true, text: "Phone number can't be empty", inputs: ["phonenumber"]}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/scalelead/createConversion`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "This user already has a lead for this offer. You will need to retry in a couple of hours"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getOffers();
    }, []);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Create lead</div>
                <div className="genericModal__wrap__head__right" onClick={props.onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            {offers ? <>
                {offers.status === "ok" ? <>
                    <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("offer") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Offer</p>
                        <Dropdown
                            theme={themeSelector}
                            accent="#6C5DD3"
                            inlinePlaceholder="Offer"
                            data={offers.data.map(o => {
                                return {name: `${o.OfferName} (${o.Country})`, value: o.ID}
                            })}
                            selected={(()=>{
                                if (!offers) return null;
                                if (offers.status !== "ok") return null;
                                if (!selectedOffer) return null;

                                return offers.data.indexOf(offers.data.find(o => o.ID === selectedOffer));
                            })()}
                            onChange={e => selectedOffer !== e?.value && setSelectedOffer(e.value)}
                        />
                    </div>

                    <div className={`genericModal__wrap__input ${infoP.text.includes("firstlastname") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>First and Last name</p>
                        <input ref={firstLastNameRef} type="text" placeholder="First and Last name" />
                    </div>

                    <div className={`genericModal__wrap__input ${infoP.text.includes("phonenumber") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Phone number</p>
                        <input ref={phoneNumberRef} type="text" placeholder="Phone number" />
                    </div>

                    <div className="genericModal__wrap__buttons">
                        <div className="genericModal__wrap__buttons__btn" onClick={createLead}>
                            {spinner ? <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Create"}
                        </div>
                    </div>

                    {infoP && <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</p>}
                </> : <p style={{
                    color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight
                }}>There was an error while fetching offers</p>}
            </> : <Spinner style={{width: "16px", height: "16px"}} color={themeSelector === "dark" ? "white" : "black"} />}
        </div>
    </div>
};

export default CreateLeadModal;