import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";

import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../customComponents/Spinner";
import { FilteredCustomTable } from "../../customComponents/Table";

const ageGroups = ["18-24", "25-34", "35-44", "45-54", "55-64", "65+"];
const AdsAgeBreakdownModal = (props) => {
    const [data, setData] = React.useState();
    const [gender, setGender] = React.useState();

    const timestampRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySelector = useSelector(state => state?.types?.currencySign ?? "?");

    const prepareData = async (ts) => {
        setData();
        let tmp = {};

        let filters = [];
        if (Array.isArray(props.filters)) filters.push(...props.filters);
        if (gender) filters.push({name: "Gender_IN", op: "eq", value: gender});

        for (let item of ageGroups) {
            if (timestampRef.current !== ts) break;
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getTrackingStats`,
                data: {
                    TableHeaders: [
                        "Impressions_IN",
                        "Clicks_IN",
                        "CTR_IN",
                        "CPC_IN",
                        "Spent_IN",
                        "CR_IN",
                        "Conversions_IN"
                    ],
                    CampaignIDs: [props.ID],
                    filters: [
                        {name: "Age_IN", op: "eq", value: item},
                        ...filters
                    ],
                    IntegrationType: 0,
                    skipIntegrationDataPull: false
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status !== "ok") return;

                let rData = res.data.data;
                if (!rData?.Integrations) return;
                if (!rData?.Integrations?.[0]) return;
                if (!rData?.Integrations?.[0]?.TableData) return;

                let tableData = rData.Integrations[0].TableData;

                tableData["CPA_IN"] = 0;
                if (tableData["Spent_IN"] && tableData["Conversions_IN"]) {
                    tableData["CPA_IN"] = tableData["Spent_IN"] / tableData["Conversions_IN"];
                };
                tmp[item] = tableData;
            }).catch(() => null);
        };

        if (timestampRef.current !== ts) return;
        setData(tmp);
    };

    const prepareTableData = (column, data) => {
        switch (column) {
            case "CTR_IN":
            case "CR_IN":
                return `${Number(data).toFixed(2)} %`;
            case "CPC_IN":
            case "Spent_IN":
            case "CPA_IN":
                return `${Number(data).toFixed(2)} ${currencySelector}`;
            default: return Number(data).toFixed(0);
        };
    };

    const getAgeData = (age, column) => {
        if (!data) return <Spinner style={{width: "12px", height: "12px"}} color={themeSelector === "dark" ? "white" : "black"} />
        if (!data[age]) return "-";

        if (data[age][column]) return prepareTableData(column, data[age][column]);
        return prepareTableData(column, 0);
    };

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        prepareData(ts);
    }, [gender, props.filters]);

    return <div className="modals__adsAgeBreakdownModal__wrap__content__left">
        <AdvancedDropdown
            headline="Gender"
            data={[
                {key: "any", name: "Any", value: null},
                {key: "male", name: "Male", value: "male"},
                {key: "female", name: "Female", value: "female"}
            ]}
            style={{
                width: "300px",
                marginBottom: "20px"
            }}
            onChange={e => e?.value !== gender && setGender(e?.value)}
            selected={(()=>{
                switch (gender) {
                    case "male": return 1;
                    case "female": return 2;
                    default: return 0;
                };
            })()}
        />
        <FilteredCustomTable
            theme={themeSelector}
            headers={["Age group", "Impressions", "Clicks", "CTR", "CPC", "Spent", "CR", "CPA"]}
            customColumns={(new Array(8)).fill("max-content")}
            style={{columnGap: "40px"}}
            data={(()=>{
                let out = [];

                for (let group of ageGroups) {
                    out.push([
                        {keyID: group, type: "text", text: group},
                        {keyID: group, type: "text", text: getAgeData(group, "Impressions_IN")},
                        {keyID: group, type: "text", text: getAgeData(group, "Clicks_IN")},
                        {keyID: group, type: "text", text: getAgeData(group, "CTR_IN")},
                        {keyID: group, type: "text", text: getAgeData(group, "CPC_IN")},
                        {keyID: group, type: "text", text: getAgeData(group, "Spent_IN")},
                        {keyID: group, type: "text", text: getAgeData(group, "CR_IN")},
                        {keyID: group, type: "text", text: getAgeData(group, "CPA_IN")},
                    ]);
                };

                return out;
            })()}
        />
    </div>
};

export default AdsAgeBreakdownModal;